/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useEffect, useState } from 'react';
import './ChangeUnitModal.scss';
import { BhModal, BhTextInput } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSelector } from 'react-redux';
import apiRequest from '../../../services/api-helper';
import { APIEnum } from '../../../constants/api-enum';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import { sortWithKey } from '../../../utils/utils';
import { dataSetForSearchAndFavoritePayload } from '../../Dashboard/helper';
declare const window: any;

export interface RefineriesData {
  children: (string | number)[];
  expanded: boolean;
  key: string;
  name: string;
}

export default function ChangeUnitModal(props: any) {
  const [ctaData, setCtaData] = useState<any>({});
  const refineryTreeFromStore = useSelector((state: any) => state.createRefineryTree);
  const refineryResponseFromStore = useSelector((state: any) => state.createRefineryResponse);
  const { header, isDismissible, isOpen, closeModal, getConfigData } = props;
  const [expanded, setExpanded] = useState<any>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState<boolean>(false);
  const [isSuccess, setSuccessFlag] = useState<boolean>(false);

  const getTreeItemsFromData = (treeItems: any) => {
    if (treeItems && treeItems.length) {
      return treeItems.map((treeItemData: any) => {
        let children;
        if (treeItemData.children && treeItemData.children.length > 0) {
          children = getTreeItemsFromData(treeItemData.children);
        }
        return (
          <TreeItem
            key={treeItemData.key}
            nodeId={treeItemData.key}
            label={treeItemData.name.trim().toLowerCase() === 'atm tower overhead' ? 'Atmospheric Tower Overhead' : 
            (treeItemData.name.trim().toLowerCase() === 'prefractionator overhead' ? 'Prefractionator Overhead' : 
            (treeItemData.name.trim().toLowerCase() === 'fractionator overhead' ? 'Fractionator Overhead':
            (treeItemData.name.trim().toLowerCase() === 'main fractionator overhead' ? 'Main Fractionator Overhead':
            treeItemData.name)))}
            children={children}
            className="change-unit-tree"
          />
        );
      });
    } else {
      return <TreeItem key="1" nodeId="1" label="No items to display" />;
    }
  };

  const handleCtaClick = (event: any) => {
    let isDisabled = document.querySelector(`bh-button[data-key="btnSave"]`)!.getAttribute('disabled');
    //save button click
    if (event.target.innerText === 'Save' && isDisabled === 'false') {
      //find the root nodes for the selected node from store
      if (refineryResponseFromStore && refineryResponseFromStore.data && refineryResponseFromStore.data.length) {
        let selectedOperator = searchSelectedNode(refineryResponseFromStore.data, selectedNodeKey);
        //api call to save changed unit details
        let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries/favourite`;
        apiRequest(encodeURI(apiUrl), selectedOperator, APIEnum.POST)
          .then((result) => {
            setExpanded([]);
            document.querySelector(`bh-button[data-key="btnSave"]`)!.setAttribute('disabled', 'true');
            if (result && result.responseStaus && result.responseStaus.status && result.responseStaus.status === 'SUCCESS') {
              setSuccessFlag(true);
              setMessage('Unit details saved successfully');
              localStorage.removeItem('simulationData');
              localStorage.removeItem('manualInput');
              localStorage.removeItem('isSandbox');
              localStorage.removeItem('sandboxsimulation');
              setTimeout(() => {
                //refineries api call to get updated config
                getConfigData();
                closeModal();
              }, 2000);
            } else {
              setErrorFlag(true);
              result &&
              result.responseStaus &&
              result.responseStaus.errorResponse &&
              result.responseStaus.errorResponse[0] &&
              result.responseStaus.errorResponse[0].message
                ? setMessage(result.responseStaus.errorResponse[0].message)
                : setMessage('Bad Request');
              setTimeout(() => {
                getConfigData();
                closeModal();
              }, 2500);
            }
          })
          .catch((error) => {
            setErrorFlag(true);
            setMessage(error.message);
            setTimeout(() => {
              getConfigData();
              closeModal();
            }, 2500);
          });
      }
    } else if (event.target.innerText === 'Cancel') {
      closeModal();
    }
  };

  const selectedNode = (event: any, node: any) => {
    setSelectedNodeKey(node);
    let nodeNames = node.split('_');
    if (nodeNames[1] === 'subunit') {
      document.querySelector(`bh-button[data-key="btnSave"]`)!.setAttribute('disabled', 'false');
    } else {
      document.querySelector(`bh-button[data-key="btnSave"]`)!.setAttribute('disabled', 'true');
    }
  };

  function searchSelectedNode(items: any[], node: string): any[] {
    let operator: any;
    let subUnitIdTemp: string;
    items.forEach((operatorItem: any) => {
      let refinery: any = [];
      if (operatorItem.refinery) {
        let unit: any = [];
        if (operatorItem.refinery.unit && operatorItem.refinery.unit.length > 0) {
          operatorItem.refinery.unit.forEach((unitItem: any) => {
            let subUnit: any = [];
            if (unitItem.subUnit && unitItem.subUnit.length > 0) {
              unitItem.subUnit.forEach((subUnitItem: any) => {
                subUnitIdTemp = subUnitItem.subUnitId + '_subunit';
                if (subUnitItem.subUnitId + '_subunit' === node) {
                  subUnit.push(subUnitItem);
                 let payLoadItems = dataSetForSearchAndFavoritePayload(unitItem, subUnit, operatorItem);
                  operator = {
                    refinery: payLoadItems.refinery,
                  };
                }
              });
            }
          });
        }
      }
    });
    return operator;
  }

  function searchTree(items: any[], term: string): any[] {
    let result;
    //if search box is not empty
    if (term) {
      result =
        items &&
        items.length &&
        items.reduce((matchedItems, item) => {
          if (item.name.toLowerCase().includes(term.trim().toLowerCase())) {
            matchedItems.push({ expanded: true, name: item.name, key: item.key, children: item.children });
          } else if (item.children && item.children.length > 0) {
            let newItems = searchTree(item.children, term.trim());

            if (newItems.length > 0) {
              matchedItems.push({ expanded: true, name: item.name, key: item.key, children: newItems });
            }
          }
          return matchedItems;
        }, []);
      let sortData = sortOrder(result, 'name', 'asc');
      setFilteredData(sortData);
    } else {
      //when search box is empty
      let sortData = sortOrder(refineryTreeFromStore.data, 'name', 'asc');
      setFilteredData(sortData);
    }
    return result;
  }

  const sortOrder = (list: any, key: string, order: string) => {
    let sortedRefinery = sortWithKey(list, key, order);
    if (Array.isArray(sortedRefinery) && sortedRefinery.length > 0) {
      sortedRefinery.forEach((item: RefineriesData) => {
        item.children = sortWithKey(item.children, key, order);
      });
    }
    return sortedRefinery;
  };

  const handleToggle = (event: React.SyntheticEvent, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  // function to expand searched nodes
  const expandSearchedNodes = (items: any[], matchedItems: any[]): any[] => {
    items.forEach((item) => {
      if (item.expanded) {
        !matchedItems.includes(item.key) && matchedItems.push(item.key);
      }
      if (item.children && item.children.length > 0) {
        let newItems = expandSearchedNodes(item.children, matchedItems);
        if (newItems.length > 0) {
          !matchedItems.includes(item.key) && matchedItems.push(item.key);
        }
      }
    });
    setExpanded(matchedItems);
    return matchedItems;
  };

  useEffect(() => {
    let ctaArray = [];
    !isDismissible
      ? ctaArray.push({ type: 'primary', label: 'Save', key: 'btnSave', isDisabled: true, class: 'cls-save' })
      : ctaArray.push(
          {
            type: 'ghost',
            label: 'Cancel',
            key: 'btnCancel',
          },
          { type: 'primary', label: 'Save', key: 'btnSave', isDisabled: true, class: 'cls-save' }
        );
    setCtaData(JSON.stringify(ctaArray));
    //bind filtered tree data
    let sortedList = sortOrder(refineryTreeFromStore.data, 'name', 'asc');
    setFilteredData(sortedList);
    setExpanded([]);
    //set error and success flags to false in component unmount
    return () => {
      setSuccessFlag(false);
      setErrorFlag(false);
    };
  }, [props]);

  return (
    <React.Fragment>
      <div style={{zIndex:40000}}>
        {isOpen ? (
          <BhModal
            isOpen={isOpen}
            header={header}
            isDismissible={isDismissible}
            ctas={ctaData}
            onClick={(event: any) => {
              handleCtaClick(event);
            }}
            onBhEventClose={closeModal}
            width="medium"
            data-testid="bh-change-unit"
            id="change-unit-modal"
          >
            <div>
              <BhTextInput
                placeholder="Search facility, unit"
                onBhEventInput={(event: any) => searchTree(refineryTreeFromStore.data, event.detail)}
                onKeyUp={(event: any) => expandSearchedNodes(filteredData, [])}
                isFluid={true}
                isSmall={true}
                type="text"
                id="txt-search"
              ></BhTextInput>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                onNodeSelect={selectedNode}
                expanded={expanded}
                onNodeToggle={handleToggle}
              >
                {getTreeItemsFromData(filteredData)}
              </TreeView>
              {isSuccess ? (
                <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success alert-message-changeunit" id="alert-message-changeunit">
                  {message}
                </Alert>
              ) : isError ? (
                <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error alert-message-changeunit" id="alert-message-changeunit">
                  {message}
                </Alert>
              ) : (
                <Alert hidden id="nodisplay-alert"></Alert>
              )}
            </div>
          </BhModal>
        ) : (
          ''
        )}
      </div>
    </React.Fragment>
  );
}