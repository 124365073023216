import { getDesalterFor1CAnd2C, overHeadConditions, towerData } from "./helper";



export const ionicInputFor1A = (formatedDate: string, elementForOneA: any, unitType: string, isWithDesalter : boolean, isFromReport : boolean, is1C2C : boolean, cType :any) => {
    let tempUnit;
    if(!isFromReport) {
      if(unitType !=='coker' && unitType !=='fcc' && cType !== '1C' &&  cType !== '2C'){ 
        if (elementForOneA.desalter.desalterTemperature_UOM === '°F' || elementForOneA.desalter.desalterTemperature_UOM === 'F') tempUnit = 'F';
        else if (elementForOneA.desalter.desalterTemperature_UOM === '°C' || elementForOneA.desalter.desalterTemperature_UOM === 'C') tempUnit = 'C';
        }
        else if(unitType === 'coker' || unitType === 'fcc' || cType === '1C'  || cType === '2C' ){
          if (elementForOneA.overheadConditions.OHTemp_UOM === '°F' || elementForOneA.overheadConditions.OHTemp_UOM === 'F') tempUnit = 'F';
          else if (elementForOneA.overheadConditions.OHTemp_UOM === '°C' || elementForOneA.overheadConditions.OHTemp_UOM === 'C') tempUnit = 'C';
        }
    }
    let payLoad = {
      inputDate: formatedDate,
        simulationId : !isFromReport ? null : elementForOneA.simulationId, 
        desalter: is1C2C ? getDesalterFor1CAnd2C(elementForOneA, tempUnit) : {
        chargeGravity: ((unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? parseFloat(elementForOneA.desalter.chargeGravity): null,
        crudeCharge: ((unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? parseFloat(elementForOneA.desalter.crudeCharge): null,
        crudeCharge_UOM: ((unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? elementForOneA.desalter.crudeCharge_UOM: null,
        crudeGravity: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.crudeGravity):null,
        washPercentageData: {
          washPercentage: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.washPercentageData.washPercentage): null,
        },
        brineRecyclePercentage: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.brineRecyclePercentage ? elementForOneA.desalter.brineRecyclePercentage : 0): null,
        percentageAtmH2OtoDesalter: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.percentageAtmH2OtoDesalter ? elementForOneA.desalter.percentageAtmH2OtoDesalter : 0): null,
        percentageAtmH2OtoSWS: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.percentageAtmH2OtoSWS ? elementForOneA.desalter.percentageAtmH2OtoSWS : 0): null,
        percentageSSWtoDesalter: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.percentageSSWtoDesalter ? elementForOneA.desalter.percentageSSWtoDesalter : 0): null,
        desalterTemperature: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.desalterTemperature): null,
        desalterTemperature_UOM: (unitType !=='coker' && unitType !=='fcc') ?(!isFromReport ? tempUnit : elementForOneA.desalter.desalterTemperature_UOM ) : null,
        brinepHData: {
          brinepH: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.brinepHData.brinepH): null,
        },
        brineSampleTemperature: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.desalter.brineSampleTemperature): null,
        desCrudeBSWData: {
          desCrudeBSW: ((unitType !=='coker' && unitType !=='fcc') ||((unitType === 'coker' || unitType === 'fcc') && isWithDesalter)) ? parseFloat(elementForOneA.desalter.desCrudeBSWData.desCrudeBSW ? elementForOneA.desalter.desCrudeBSWData.desCrudeBSW : 0): null,
        },
      },
      towerData: towerData(elementForOneA,unitType,isWithDesalter),
      overheadConditions: overHeadConditions(elementForOneA,isFromReport, tempUnit),
      liquidAssayStreams: {
        isWetReflux: !isFromReport ? (elementForOneA.liquidAssayStreams.isWetReflux === true || elementForOneA.liquidAssayStreams.isWetReflux === 'TRUE' ? true : false) : elementForOneA.liquidAssayStreams.isWetReflux ? true : false,
        hydrocarbonLiquid: {
          reflux: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.reflux ? elementForOneA.liquidAssayStreams.hydrocarbonLiquid.reflux : 0),
          reflux_UOM: elementForOneA.liquidAssayStreams.hydrocarbonLiquid.reflux_UOM,
          product: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.product),
          product_UOM: elementForOneA.liquidAssayStreams.hydrocarbonLiquid.product_UOM,
          gravity: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.gravity),
          distillationType: elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillationType,
          distillation: {
            0: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[0]),
            5: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[5]),
            10: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[10]),
            20: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[20]),
            30: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[30]),
            50: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[50]),
            70: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[70]),
            80: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[80]),
            90: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[90]),
            95: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[95]),
            100: parseFloat(elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation[100]),
            distillation_UOM: !isFromReport ? tempUnit : elementForOneA.liquidAssayStreams.hydrocarbonLiquid.distillation.distillation_UOM,
          },
        },
      },
      offGasData: {
        offGas: parseFloat(elementForOneA.offGasData.offGas ? elementForOneA.offGasData.offGas : 0),
        offGas_UOM: elementForOneA.offGasData.offGas_UOM,
        H2: parseFloat(elementForOneA.offGasData.H2 ? elementForOneA.offGasData.H2 : 0),
        H2S: parseFloat(elementForOneA.offGasData.H2S ? elementForOneA.offGasData.H2S : 0),
        CO2: parseFloat(elementForOneA.offGasData.CO2 ? elementForOneA.offGasData.CO2 : 0),
        C1: parseFloat(elementForOneA.offGasData.C1 ? elementForOneA.offGasData.C1 : 0),
        C2: parseFloat(elementForOneA.offGasData.C2 ? elementForOneA.offGasData.C2 : 0),
        C3: parseFloat(elementForOneA.offGasData.C3 ? elementForOneA.offGasData.C3 : 0),
        iC4: parseFloat(elementForOneA.offGasData.iC4 ? elementForOneA.offGasData.iC4 : 0),
        nC4: parseFloat(elementForOneA.offGasData.nC4 ? elementForOneA.offGasData.nC4 : 0),
        iC5: parseFloat(elementForOneA.offGasData.iC5 ? elementForOneA.offGasData.iC5 : 0),
        nC5: parseFloat(elementForOneA.offGasData.nC5 ? elementForOneA.offGasData.nC5 : 0),
        nC6: parseFloat(elementForOneA.offGasData.nC6 ? elementForOneA.offGasData.nC6 : 0),
      },
      overheadWater: {
        combinedRefluxData :{
          combinedRefluxFlow: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.overheadWater.combinedRefluxData.combinedRefluxFlow ? elementForOneA.overheadWater.combinedRefluxData.combinedRefluxFlow  : 0) : null,
          combinedRefluxTemp : (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.overheadWater.combinedRefluxData.combinedRefluxTemp ? elementForOneA.overheadWater.combinedRefluxData.combinedRefluxTemp  : 0) : null,
          combinedRefluxFlow_UOM: (unitType !=='coker' && unitType !=='fcc') ? elementForOneA.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM : null,
          combinedRefluxTemp_UOM :  (unitType !=='coker' && unitType !=='fcc') ? (!isFromReport ? tempUnit : elementForOneA.overheadWater.combinedRefluxData.combinedRefluxTemp_UOM) : null,
        },
        pHData: {
          pH: parseFloat(elementForOneA.overheadWater.pHData.pH),
        },
        clData: {
          cl: parseFloat(elementForOneA.overheadWater.clData.cl ? elementForOneA.overheadWater.clData.cl : 0),
        },
        bromideData: {
          bromide: parseFloat(elementForOneA.overheadWater.bromideData.bromide ? elementForOneA.overheadWater.bromideData.bromide : 0),
        },
        NH3Data: {
          NH3: parseFloat(elementForOneA.overheadWater.NH3Data.NH3 ? elementForOneA.overheadWater.NH3Data.NH3 : 0),
        },
        sulfide: (unitType === 'coker' || unitType === 'fcc') ? parseFloat(elementForOneA.overheadWater.sulfide ? elementForOneA.overheadWater.sulfide : 0): null,
        neutralizerName: (unitType !=='coker' && unitType !=='fcc') ? elementForOneA.overheadWater.neutralizerName: null,
        neutralizerRate: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.overheadWater.neutralizerRate ? elementForOneA.overheadWater.neutralizerRate : 0): null,
        neutralizerRate_UOM: (unitType !=='coker' && unitType !=='fcc') ? elementForOneA.overheadWater.neutralizerRate_UOM: null,
        outsideNeutralizer: (unitType !=='coker' && unitType !=='fcc') ? parseFloat(elementForOneA.overheadWater.outsideNeutralizer ? elementForOneA.overheadWater.outsideNeutralizer : 0): null,
        outsideNeutralizer_UOM: (unitType !=='coker' && unitType !=='fcc') ? elementForOneA.overheadWater.outsideNeutralizer_UOM: null,
        trampAmineData: {
          trampAmine: elementForOneA.overheadWater.trampAmineData.trampAmine,
          trampAmineppm: parseFloat(
            elementForOneA.overheadWater.trampAmineData.trampAmineppm ? elementForOneA.overheadWater.trampAmineData.trampAmineppm : 0
          ),
        },
        steamAmineData: {
          steamAmine: elementForOneA.overheadWater.steamAmineData.steamAmine,
          steamAmineppm: parseFloat(
            elementForOneA.overheadWater.steamAmineData.steamAmineppm ? elementForOneA.overheadWater.steamAmineData.steamAmineppm : 0
          ),
        },
      },
    };
    return payLoad;
  };