/*******************************************************************************
 * Baker Hughes Highly Confidential
 * Copyright 2022. Baker Hughes
 *
 * NOTICE: All information contained herein is, and remains the property of Baker Hughes, and/or
 * its affiliates. The intellectual and technical concepts contained herein are proprietary to Baker Hughes
 * and/or its affiliates and may be covered by patents, copyrights, and/or trade secrets. Dissemination of this information or
 * reproduction of this material is strictly forbidden unless prior written permission is obtained from Baker Hughes.
 ******************************************************************************/
import React, { useLayoutEffect, useState } from 'react';
import {
  BhButton,
  BhButtonTabs,
  BhDivider,
  BhDropdown,
  BhFormMessage,
  BhIllustration,
  BhModal,
  BhSpinner,
  BhTabs,
  BhTextInput,
  BhTitleWrapper,
} from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import {
  Alert,
  Backdrop,
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TablePaginationProps,
  TextField,
} from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import {
  DataGrid,
  GridColDef,
  GridPagination,
  gridPageCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import './AdminCenter.scss';
import ManageUserModal from '../Shared/Modal/ManageUserModal';
import RequestDetails from './RequestDetails';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { decryptData } from '../../utils/utils';
import ImportAssetsModal from '../Shared/Modal/ImportAssetsModal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SecurityIcon from '@mui/icons-material/Security';
import { useLocation } from 'react-router-dom';
import { BootstrapDialog, BootstrapDialogTitle } from '../Dashboard/helper';
declare const window: any;


export default function AdminCenter(props: any) {
  const location = useLocation();
  const [isUserTableOpen, setUserTableOpen] = useState(location.state && location.state.changeRequestTab ? 'change-request' : 'user');
  const [userData, setUserData] = useState<any>([]);
  const [userDataFromApi, setUserDataFromApi] = useState<any>([]);
  const [assetData, setAssetData] = useState<any>([]);
  const [assetDataFromApi, setAssetDataFromApi] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setOpenFlag] = useState(false);
  const [isAssetOpen, setAssetOpenFlag] = useState(false);
  const [isAddUser, setAddUser] = useState(false);
  const [roles, setRoles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [refineries, setRefineries] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('All');
  const [selectedRefinery, setSelectedRefinery] = useState<string>('All');
  const [searchedText, setSearchedText] = useState('');
  const [searchedAsset, setSearchedAsset] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElAsset, setAnchorElAsset] = useState(null);
  const [role, setRole] = useState<any>({});
  const [message, setMessage] = useState('');
  const [isError, setErrorFlag] = useState(false);
  const [isSuccess, setSuccessFlag] = useState(false);
  const [isEditError, setEditErrorFlag] = useState(false);
  const [isEditSuccess, setEditSuccessFlag] = useState(false);
  const [editMessage, setEditMessage] = useState('');
  const [paginationModelUser, setPaginationModelUser] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [paginationModelAsset, setPaginationModelAsset] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const open = Boolean(anchorEl);
  const openAssetMenu = Boolean(anchorElAsset);
  const [showSyncData, setShowSyncData] = useState(false);
  const [showEditData,setShowEditData] = useState(false);
  const [ctaData, setCtaData] = useState<any>({});
  const [customer, setCustomer] = useState('');
  const [refinery, setRefinery] = useState('');
  const [isCustomerError,setCustomerError] = useState(false);
  const [isRefineryError,setRefineryError] = useState(false);
  const [editedAssetData, setEditedAssetData] = useState<any>({});
  const [syncDataChangeObj, setSyncDataChangeObj] = useState<any>([]);

  const [selectedTab, setSelectedTab] = useState<string>(location.state && location.state.subTab ? location.state.subTab : 'Pending');
  const [pageHeader, setPageHeader] = useState('');

  const userColumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'name',
      headerName: 'Name',
      width: 210,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 270,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 180,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 200,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'refinery',
      headerName: 'Refinery',
      width: 320,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 138,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'edit',
      headerName: '',
      width: 10,
      sortable: false,
      headerClassName: 'grid-header',
      renderCell: (params) => renderHorizontalMenu(params.row, true),
      flex: 1,
    },
  ];

  const assetColumns: GridColDef[] = [
    { field: 'id', headerName: '', headerClassName: 'grid-header', flex: 1 },
    {
      field: 'customer',
      headerName: 'Customer',
      width: 365,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0
    },
    {
      field: 'refinery',
      headerName: 'Refinery',
      width: 365,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 300,
      sortable: true,
      headerClassName: 'grid-header',
      flex: 0,
    },
    {
      field: 'edit',
      headerName: '',
      width: 10,
      sortable: false,
      headerClassName: 'grid-header',
      renderCell: (params) => renderHorizontalMenu(params.row, false),
      flex: 1,
    },
  ];
  useLayoutEffect(() => {

    let decryptedRole: any;
    if (localStorage.getItem('salt') !== null) {
      let dataFromStorage = localStorage.getItem('salt')!;
      decryptedRole = decryptData(dataFromStorage);
      (decryptedRole.userRole.toLowerCase() === 'sme' || decryptedRole.userRole.toLowerCase() === 'account manager') && setUserTableOpen('change-request');
      setRole(decryptedRole);
    //api call to get notifications
    if(isUserTableOpen === 'change-request' || decryptedRole.userRole.toLowerCase() === 'sme' || decryptedRole.userRole.toLowerCase() === 'account manager'){
      decryptedRole.userRole.toLowerCase() === 'account manager' ? setPageHeader('Message center') : setPageHeader('Admin center');
      if(!location || !location.state || !location.state.changeRequestTab){
      props.getNotification(true);
      }
     }
     if(decryptedRole.userRole.toLowerCase() === 'admin'){
    setPageHeader('Admin center');
    // api call to get user details
    setIsLoading(true);
    getUserData();
    // api call to get user roles which needs to show in create/manage user screen
    getUserRoles();
    //api call to get all customers which needs to show in create/manage user screen
    getAllCustomers();
  }
    let ctaArray = [];
    ctaArray.push(
      {
        type: 'ghost',
        label: 'Cancel',
        key: 'btnCancel',
      },
      { type: 'primary', label: 'Save', key: 'btnSave' }
    );
    setCtaData(JSON.stringify(ctaArray));
    }
    return () => {
      setSearchedText('');
      setSearchedAsset('');
      setSelectedCustomer('All');
      setSelectedRefinery('All');
      setUserData([]);
      setUserDataFromApi([]);
    };
  }, []);

  const handleMenuClick = (event: any, details: any, isUser: boolean) => {
    if (isUser) {
      setAnchorEl(event.currentTarget);
      setUserDetails(details);
    } else {
      setAnchorElAsset(event.currentTarget);
      setEditedAssetData(details);
    }
  };

  const handleMenuClose = (isUser: boolean) => {
    if (isUser) {
      setAnchorEl(null);
      setUserDetails({});
    } else {
      setAnchorElAsset(null);
    }
  };

  const renderHorizontalMenu = (row: any, isUser: boolean) => {
    return (
      <div style={{ marginLeft: '-5px' }}>
        <IconButton aria-label="settings">
          <MoreHorizIcon onClick={(event) => handleMenuClick(event, isUser ? row?.userDetails : row, isUser)} />
        </IconButton>
      </div>
    );
  };

  const getUserRoles = () => {
    let userRoles: any = [];
    let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/roles`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((response) => {
        if (response && response.length) {
          response.reverse().forEach((roleItem: any) => {
            userRoles.push({
              label: roleItem.roleName,
              value: roleItem.roleId,
              id: roleItem.roleId,
            });
          });
          setRoles(userRoles);
        }
      })
      .catch((error) => {});
  };

  const getAssetsData = () => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries`;
    setIsLoading(true);
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result && result.responseStatus && result.responseStatus.status.toLowerCase() === 'success') {
          if (result.data && result.data.length) {
            result.data.forEach((dataItem: any) => {
              payLoad.data.push({
                id: dataItem.refineryId,
                customer: dataItem.customer,
                refinery: dataItem.refinery,
                customerId:dataItem.customerId,
                source: 'IDS',
                customerIDSName:dataItem.customerIDSName,
                refineryIDSName:dataItem.refineryIDSName
              });
            });
            payLoad.length = result.data.length;
            if(searchedAsset && searchedAsset !== ''){
              filterAssetPayload(payLoad);
            }
            else{
              setAssetData(payLoad);
            }   
            setAssetDataFromApi(payLoad);        
          }
        } else if (result && result.responseStatus && result.responseStatus.status.toLowerCase() === 'error') {
          setAssetData([]);
          setAssetDataFromApi([]);
        }
      })
      .catch((error) => {
        setAssetData([]);
        setAssetDataFromApi([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchRefineriesOfSelectedCustomer = (customerId: number) => {
    setSelectedRefinery('All');
    let filterRefineries: any = [{ label: 'All', value: 0, id: 0 }];
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/customers/${customerId}/refineries`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((response) => {
        if (response && response.responseStatus && response.responseStatus.status.toLowerCase() === 'success') {
          if (response.data && response.data.length) {
            response.data.forEach((refineryItem: any) => {
              filterRefineries.push({
                label: refineryItem.refineryName,
                value: refineryItem.refineryId,
                id: refineryItem.refineryId,
              });
            });
            setRefineries(filterRefineries);
          }
        }
      })
      .catch((error) => {
        setErrorFlag(true);
      });
  };

  const filterPayLoad = (payLoadData: any) => {
    if (searchedText && searchedText !== '') {
      let filteredRows;
      //customer and refinery dropdown has values
      if (selectedCustomer !== 'All' && selectedRefinery !== 'All') {
        filteredRows = payLoadData.data.filter((row: any) => {
          return (
            ((row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()) &&
              row.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase())) ||
              row.customer === 'All') &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
      } else if (selectedCustomer !== 'All' && selectedRefinery === 'All') {
        filteredRows = payLoadData.data.filter((row: any) => {
          return (
            (row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()) || row.customer === 'All') &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.customer.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.refinery.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
      } else if (selectedRefinery !== 'All' && selectedCustomer === 'All') {
        filteredRows = payLoadData.data.filter((row: any) => {
          return (
            (row.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase()) || row.customer === 'All') &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.customer.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.refinery.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
        //case when both customer and refinery not selected
      } else {
        filteredRows = payLoadData.data.filter((row: any) => {
          return (
            row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.customer.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.refinery.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.status.toLowerCase().includes(searchedText.trim().toLowerCase())
          );
        });
      }
      let data: any = [];
      let length: any;
      let payLoad = {
        data,
        length,
      };
      payLoad.data = filteredRows;
      payLoad.length = filteredRows.length;
      setUserData(payLoad);
    } else {
      if (selectedCustomer !== 'All' && selectedRefinery !== 'All') {
        const filteredRowsData = payLoadData.data.filter((rowData: any) => {
          return (
            rowData.customer === 'All' ||
            (rowData.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()) &&
            rowData.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase()))
          );
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRowsData;
        payLoad.length = filteredRowsData.length;
        setUserData(payLoad);
      } else if (selectedCustomer !== 'All' && selectedRefinery === 'All') {
        const filteredRowsItem = payLoadData.data.filter((rowItem: any) => {
          return rowItem.customer === 'All' || rowItem.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase());
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRowsItem;
        payLoad.length = filteredRowsItem.length;
        setUserData(payLoad);
      } else if (selectedRefinery !== 'All' && selectedCustomer === 'All') {
        const filteredRows = payLoadData.data.filter((row: any) => {
          return row.customer === 'All' || row.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase());
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      } else {
        setUserData(payLoadData);
      }
    }
  };

  const getUserData = () => {
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/usermanagement`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result && result.responseStatus && result.responseStatus.status.toLowerCase() === 'success') {
          if (result.data && result.data.length) {
            result.data.forEach((resultItem: any) => {
              if (resultItem.userDetails) {
                //map customer and refinery
                let customerName: string = '';
                let refineryName: string = '';
                let customerIdArray: number[] = [];
                if (resultItem.userEntitlements && resultItem.userEntitlements.length) {
                  resultItem.userEntitlements.forEach((entitleItem: any) => {
                    if (entitleItem.refinery && entitleItem.refinery.length) {
                      entitleItem.refinery.forEach((refineryItem: any) => {
                        if (refineryItem.isEntitled) {
                          refineryName = refineryName + refineryItem.refineryName + ', ';
                        }
                      });
                    }
                    if (!customerIdArray.includes(entitleItem.companyId)) {
                      if (resultItem.userEntitlements.length === 1) customerName = customerName + entitleItem.companyName;
                      else if (customerIdArray.length === 1) customerName = customerName + entitleItem.companyName;
                      else customerName = customerName + entitleItem.companyName + ', ';
                      customerIdArray.push(entitleItem.companyId);
                    }
                  });
                }
                let userDetails = {
                  userDetails: {
                    userId: resultItem.userDetails.userId,
                    fullName: resultItem.userDetails.firstName + ' ' + resultItem.userDetails.lastName,
                    isActive: resultItem.userDetails.isActive,
                  },
                  userRole: resultItem.userRole,
                  userEntitlements: resultItem.userEntitlements,
                };
                payLoad.data.push({
                  id: resultItem.userDetails.userId,
                  name: resultItem.userDetails.firstName + ' ' + resultItem.userDetails.lastName,
                  email: resultItem.userDetails.email,
                  role: resultItem.userRole && resultItem.userRole.roleName ? resultItem.userRole.roleName : '',
                  customer:
                    resultItem.userRole &&
                    resultItem.userRole.roleName &&
                    (resultItem.userRole.roleName.toLowerCase() === 'admin' || resultItem.userRole.roleName.toLowerCase() === 'sme')
                      ? 'All'
                      : customerName,
                  refinery:
                    resultItem.userRole &&
                    resultItem.userRole.roleName &&
                    (resultItem.userRole.roleName.toLowerCase() === 'admin' || resultItem.userRole.roleName.toLowerCase() === 'sme')
                      ? 'All'
                      : refineryName
                      ? refineryName.slice(0, -2)
                      : '',
                  status: resultItem.userDetails.isActive ? 'Active' : 'Inactive',
                  userDetails: userDetails,
                });
              }
            });
            let filterRefineries: any = [{ label: 'All', value: 0, id: 0 }];
            setRefineries(filterRefineries);
            if ((searchedText && searchedText !== '') || selectedRefinery !== 'All' || selectedCustomer !== 'All') {
              filterPayLoad(payLoad);
            } else {
              payLoad.length = result.data.length;
              setUserData(payLoad);
            }
            setUserDataFromApi(payLoad);
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const selectedTabClick = (event: CustomEvent) => {
    if (event.detail.key == 'user') {
      setPaginationModelUser({pageSize:5, page:0});
      // api call to get user details
      setIsLoading(true);
      getUserData();
      // api call to get user roles which needs to show in create/manage user screen
      getUserRoles();
      //api call to get all customers which needs to show in create/manage user screen
      getAllCustomers();
      setUserTableOpen('user');
    } else if (event.detail.key == 'asset') {
      setPaginationModelAsset({pageSize:5, page:0});
      setSearchedText('');
      setSearchedAsset('');
      setSelectedCustomer('All');
      setSelectedRefinery('All');
      setUserData([]);
      setUserDataFromApi([]);
      setUserTableOpen('asset');
      getAssetsData();
    } else if (event.detail.key == 'change-request') {
      setUserTableOpen('change-request');
      if(!location || !location.state || !location.state.changeRequestTab){
        props.getNotification(true);
      }
    }
  };

  const PaginationForAdmin = ({ page, onPageChange, className }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) => {
    const apiRefAdmin = useGridApiContext();
    const pageCountAdmin = useGridSelector(apiRefAdmin, gridPageCountSelector);
    return (
      <MuiPagination
        className={className}
        count={pageCountAdmin}
        page={page + 1}
        style={{ display: 'flex' }}
        onChange={(event: any, newPage) => {
          onPageChange(event as any, newPage - 1);
        }}
      />
    );
  };

  const CustomPaginationForAdmin = (props: any) => {
    return <GridPagination ActionsComponent={PaginationForAdmin} {...props} labelRowsPerPage={'Items per page'} />;
  };

  const CustomNoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No data available
      </Stack>
    );
  };

  const handleOnBoardAssetClick = () => {
    setAssetOpenFlag(true);
  };

  const handleAddUserClick = () => {
    setOpenFlag(true);
    setAddUser(true);
  };

  const handleUserExportClick = () => {
    let apiUrl = `${window.extended?.IONIC_APP_ACCESS_API_KEY}/users/export`;
    let headerObject: any = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('okta-token-storage') || '{}')?.accessToken?.accessToken}`,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    };
    fetch(apiUrl, headerObject)
      .then((response) => {
        if (response) {
          const filename = 'userlist.xlsx';
          response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
          });
        } else {
          setErrorFlag(true);
          setMessage('Export failed');
          setTimeout(() => {
            setErrorFlag(false);
          }, 4000);
        }
      })
      .catch((error) => {
        setErrorFlag(true);
        setMessage('Export failed');
        setTimeout(() => {
          setErrorFlag(false);
        }, 4000);
      });
  };

  const handleManageUserClick = () => {
    setAnchorEl(null);
    setOpenFlag(true);
    setAddUser(false);
  };

  const handleEditDataClick = (event: any) => {
    setCustomer('');
    setRefinery('');
    setEditMessage('');
    setEditSuccessFlag(false);
    setEditErrorFlag(false);
    setCustomer(editedAssetData.customer);
    setRefinery(editedAssetData.refinery)
    setCustomerError(false);
    setRefineryError(false);
    setAnchorElAsset(null);
    setShowEditData(true);
  }

  const createChangeSetObject = (changeObj:any) =>{
    let messageArray: any = [];
    if(changeObj.length){
    changeObj.forEach((changesetItem:any) => {
      //refinery level change
      if(!changesetItem.unitName && changesetItem.refineryName){
        messageArray.push({'header':'Refinery Level Changes for - ' + changesetItem.refineryName});
        if(changesetItem.changes && changesetItem.changes.length){
          changesetItem.changes.forEach((refineryChangeItem:any) => {
            messageArray.push(refineryChangeItem);
        });
      }
      //case when units added or removed
      if(changesetItem.units){
        if(changesetItem.units.added.length){
          messageArray.push({'header':'Units added for - ' + changesetItem.refineryName});
          changesetItem.units.added.forEach((addedItem:any) => {
            messageArray.push(addedItem);
          });
        }
        if(changesetItem.units.deleted.length){
          messageArray.push({'header':'Units deleted for - ' + changesetItem.refineryName});
          changesetItem.units.deleted.forEach((deletedItem:any) => {
            messageArray.push(deletedItem);
          });
        }
      }
      }
      //unit level change
      if(!changesetItem.subUnitName && changesetItem.unitName){
        messageArray.push({'header':'Unit Level Changes for - ' + changesetItem.unitName});
        if(changesetItem.changes && changesetItem.changes.length){
          changesetItem.changes.forEach((refineryChangeItem:any) => {
            messageArray.push(refineryChangeItem);
        });
      }
       //case when sub units added or removed
       if(changesetItem.subUnits){
        if(changesetItem.subUnits.added.length){
          messageArray.push({'header':'Sub Units added for - ' + changesetItem.refineryName +'(Unit : '+changesetItem.unitName+')'});
          changesetItem.subUnits.added.forEach((addedItem:any) => {
            messageArray.push(addedItem);
          });
        }
        if(changesetItem.subUnits.deleted.length){
          messageArray.push({'header':'Sub Units deleted for - ' + changesetItem.refineryName +'(Unit : '+changesetItem.unitName+')'});
          changesetItem.subUnits.deleted.forEach((deletedItem:any) => {
            messageArray.push(deletedItem);
          });
        }
      }
      }
      //sub unit level change
      if(changesetItem.subUnitName){
        messageArray.push({'header':'Sub Unit Level Changes for - ' + changesetItem.subUnitName +'(Unit : '+changesetItem.unitName+')'});
        if(changesetItem.changes && changesetItem.changes.length){
          changesetItem.changes.forEach((refineryChangeItem:any) => {
            messageArray.push(refineryChangeItem);
        });
      }
      }
    });
   }
   else{
    messageArray.push({'NoChange':'No changes found!'});
   }
   setSyncDataChangeObj(messageArray);
   setShowSyncData(true);
  }

  const handleSyncDataClick = (event: any) => {
    setAnchorElAsset(null);
    setIsLoading(true);
    //api call to fetch data from IDS
    let selectedItems: any = [];
    selectedItems.push({
      customer: editedAssetData.customerIDSName,
      refinery: editedAssetData.refineryIDSName,
    });
    let itemsToOnboard = {
      refineries: selectedItems,
    };
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries`;
    apiRequest(encodeURI(apiUrl), itemsToOnboard, APIEnum.POST)
      .then((result) => {
        if (result && result.dataChanges) {
          createChangeSetObject(result.dataChanges);
        } else {
          setErrorFlag(true);
          if (result && result.responseStatus && result.responseStatus.status.toLowerCase() === 'error') {
            if (result.responseStatus.errorResponse && result.responseStatus.errorResponse.length) {
              setMessage(result.responseStatus.errorResponse[0].message);
            }
          }
          setTimeout(() => {
            setErrorFlag(false);
          }, 4000);
        }
      })
      .catch((error) => {
        setErrorFlag(true);
        setMessage(error.message);
        setTimeout(() => {
          setErrorFlag(false);
        }, 4000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleBackDropClose = () => {
    setIsLoading(false);
  };

  const filterAssetPayload =(payLoadData:any)=>{
    let filteredRows = payLoadData.data.filter((row: any) => {
      return (
        row.customer.toLowerCase().includes(searchedAsset.trim().toLowerCase()) ||
        row.refinery.toLowerCase().includes(searchedAsset.trim().toLowerCase()) ||
        row.source.toLowerCase().includes(searchedAsset.trim().toLowerCase())
      );
    });
    let data: any = [];
    let length: any;
    let payLoad = {
      data,
      length,
    };
    payLoad.data = filteredRows;
    payLoad.length = filteredRows.length;
    setAssetData(payLoad);
  }

  //asset management search
  const requestAssetSearch = (event: any) => {
    if (event.target.value && event.target.value.trim() !== '') {
      setSearchedAsset(event.target.value); 
      let filteredRows = assetDataFromApi.data.filter((row: any) => {
        return (
          row.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
          row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
          row.source.toLowerCase().includes(event.target.value.trim().toLowerCase())
        );
      });
      let data: any = [];
      let length: any;
      let payLoad = {
        data,
        length,
      };
      payLoad.data = filteredRows;
      payLoad.length = filteredRows.length;
      setAssetData(payLoad);
    } else {
      setSearchedAsset('');
      setAssetData(assetDataFromApi);
    }
  };

  //user management search
  const requestSearch = (event: any) => {
    if (event.target.value && event.target.value.trim() !== '') {
      setSearchedText(event.target.value);
      let filteredRows;
      //customer and refinery dropdown has values
      if (selectedCustomer !== 'All' && selectedRefinery !== 'All') {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            ((row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()) &&
              row.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase())) ||
              row.customer === 'All') &&
            (row.name.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(event.target.value.trim().toLowerCase()))
          );
        });
      } else if (selectedCustomer !== 'All' && selectedRefinery === 'All') {
        filteredRows = userDataFromApi.data.filter((rowAll: any) => {
          return (
            (rowAll.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()) || rowAll.customer === 'All') &&
            (rowAll.name.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            rowAll.email.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            rowAll.role.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            rowAll.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            rowAll.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            rowAll.status.toLowerCase().includes(event.target.value.trim().toLowerCase()))
          );
        });
      } else if (selectedRefinery !== 'All' && selectedCustomer === 'All') {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            (row.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase()) || row.customer === 'All') &&
            (row.name.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(event.target.value.trim().toLowerCase()))
          );
        });
        //case when both customer and refinery not selected
      } else {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            row.name.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            row.email.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            row.role.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            row.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) ||
            row.status.toLowerCase().includes(event.target.value.trim().toLowerCase())
          );
        });
      }
      let data: any = [];
      let length: any;
      let payLoad = {
        data,
        length,
      };
      payLoad.data = filteredRows;
      payLoad.length = filteredRows.length;
      setUserData(payLoad);
    } else {
      setSearchedText('');
      if (selectedCustomer !== 'All' && selectedRefinery !== 'All') {
        const filteredRowsValue = userDataFromApi.data.filter((filValue: any) => {
          return (
            filValue.customer === 'All' ||
            (filValue.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()) &&
            filValue.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase()))
          );
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRowsValue;
        payLoad.length = filteredRowsValue.length;
        setUserData(payLoad);
      } else if (selectedCustomer !== 'All' && selectedRefinery === 'All') {
        const filteredRows = userDataFromApi.data.filter((rowCustomer: any) => {
          return rowCustomer.customer === 'All' || rowCustomer.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase());
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      } else if (selectedRefinery !== 'All' && selectedCustomer === 'All') {
        const filteredRows = userDataFromApi.data.filter((rowRefinery: any) => {
          return rowRefinery.customer === 'All' || rowRefinery.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase());
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      } else {
        setUserData(userDataFromApi);
      }
    }
  };

  const closeModal = () => {
    setOpenFlag(false);
  };

  const closeAssetModal = () => {
    setAssetOpenFlag(false);
  };

  const handleCustomerFilterChange = (event: any) => {
    setSelectedCustomer(event.target.value);
    if (event.target.value && event.target.value.trim() !== '' && event.target.value.trim() !== 'All') {
      fetchRefineriesOfSelectedCustomer(event.target.selectedValue);
      let filteredRows;
      let selectedRefinery = 'All';
      if (selectedRefinery === 'All' && (!searchedText || searchedText.trim() === '')) {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return row.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) || row.customer === 'All';
        });
      } else if (selectedRefinery === 'All' && searchedText && searchedText.trim() !== '') {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            (row.customer === 'All' || row.customer.toLowerCase().includes(event.target.value.trim().toLowerCase())) &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
      } else if (selectedRefinery !== 'All' && (!searchedText || searchedText.trim() === '')) {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            row.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) &&
            row.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase() || row.customer === 'All')
          );
        });
      } else if (selectedRefinery !== 'All' && searchedText && searchedText.trim() !== '') {
        filteredRows = userDataFromApi.data.filter((rowDetails: any) => {
          return (
            (rowDetails.customer === 'All' ||
              (rowDetails.customer.toLowerCase().includes(event.target.value.trim().toLowerCase()) &&
              rowDetails.refinery.toLowerCase().includes(selectedRefinery.trim().toLowerCase()))) &&
            (rowDetails.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            rowDetails.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            rowDetails.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            rowDetails.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
      }
      let data: any = [];
      let length: any;
      let payLoad = {
        data,
        length,
      };
      payLoad.data = filteredRows;
      payLoad.length = filteredRows.length;
      setUserData(payLoad);
    } else {
      //clear refineries if cusotmer drop down has 'All' as value
      let filterRefineries: any = [{ label: 'All', value: 0, id: 0 }];
      setRefineries(filterRefineries);
      setSelectedRefinery('All');
      //case when customer - all and refinery-all and searchbox empty
      if (!searchedText || searchedText.trim() === '') {
        setUserData(userDataFromApi);
      } else if (searchedText && searchedText.trim() !== '') {
        const filteredRows = userDataFromApi.data.filter((rowSearch: any) => {
          return (
            rowSearch.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            rowSearch.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            rowSearch.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            rowSearch.status.toLowerCase().includes(searchedText.trim().toLowerCase())
          );
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      }
    }
  };

  const getAllCustomers = () => {
    let filterCustomers: any = [{ label: 'All', value: 0, id: 0 }];
    let customers: any = [];
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/customers`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((response) => {
        if (response && response.responseStatus && response.responseStatus.status.toLowerCase() === 'success') {
          if (response.customerData && response.customerData.length)
            response.customerData.forEach((customerItem: any) => {
              customers.push({
                label: customerItem.customerName,
                value: customerItem.customerId,
                id: customerItem.customerId,
                selected: false,
              });
              filterCustomers.push({
                label: customerItem.customerName,
                value: customerItem.customerId,
                id: customerItem.customerId,
              });
            });
          setAllCustomers(customers);
          setCustomers(filterCustomers);
        }
      })
      .catch((error) => {});
  };

  const closeSyncDataModal = () => {
    setShowSyncData(false);
  }

  const closeEditDataModal =()=>{
    setShowEditData(false);
  }

  const handleCtaClick = (event: CustomEvent) => {
    if (event.detail === 'btnCancel') closeEditDataModal();
    else if (event.detail === 'btnSave') {
      if(customer && refinery){
        //api call to save customer and refinery
        let editedAssets = {
          customerId: editedAssetData.customerId,
          customerName: customer,
          refineryId: editedAssetData.id,
          refineryName: refinery
        };
        let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/customer/refinery`;
        apiRequest(encodeURI(apiUrl), editedAssets, APIEnum.POST)
          .then((result) => {
            if (result && result.responseStatus && result.responseStatus.status && result.responseStatus.status === 'SUCCESS') {
              setEditSuccessFlag(true);
              setEditMessage('Asset details updated successfully!');
            }
          })
          .catch((error) => {
            setEditErrorFlag(true);
            setEditMessage(error.message);
            setTimeout(() => {
              setEditErrorFlag(false);
            }, 4000);
          })
          .finally(() => {
            setIsLoading(false);
            setTimeout(() => {
            setShowEditData(false);
            getAssetsData();
            props.getConfigData();
          }, 2000);
          });
      }
      else{
        if(!customer) setCustomerError(true);
        if(!refinery) setRefineryError(true);
      }
    }
   }

   const handleInputChange = (event: any) => {
    if(event.target.id === 'txt-customer'){
      if(event.target.value){
        setCustomer(event.target.value);
        setCustomerError(false);
      }
      else{
        setCustomer('');
        setCustomerError(true);
      }
  
    }
  };

  const handleRefineryFilterChange = (event: any) => {
    setSelectedRefinery(event.target.value);
    if (event.target.value && event.target.value.trim() !== '' && event.target.value.trim() !== 'All') {
      let filteredRows;
      if (selectedCustomer === 'All' && (!searchedText || searchedText.trim() === '')) {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase() || row.customer === 'All');
        });
      } else if (selectedCustomer === 'All' && searchedText && searchedText.trim() !== '') {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
      } else if (selectedCustomer !== 'All' && (!searchedText || searchedText.trim() === '')) {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            (row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) &&
              row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase())) ||
            row.customer === 'All'
          );
        });
      } else if (selectedCustomer !== 'All' && searchedText && searchedText.trim() !== '') {
        filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            (row.customer === 'All' ||
              (row.refinery.toLowerCase().includes(event.target.value.trim().toLowerCase()) &&
                row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase()))) &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
      }
      let data: any = [];
      let length: any;
      let payLoad = {
        data,
        length,
      };
      payLoad.data = filteredRows;
      payLoad.length = filteredRows.length;
      setUserData(payLoad);
    } else {
      if (selectedCustomer !== 'All' && (!searchedText || searchedText.trim() === '')) {
        const filteredRows = userDataFromApi.data.filter((row: any) => {
          return row.customer === 'All' || row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase());
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      } else if (selectedCustomer !== 'All' && searchedText && searchedText.trim() !== '') {
        const filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            (row.customer === 'All' || row.customer.toLowerCase().includes(selectedCustomer.trim().toLowerCase())) &&
            (row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
              row.status.toLowerCase().includes(searchedText.trim().toLowerCase()))
          );
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      } else if (selectedCustomer === 'All' && (!searchedText || searchedText.trim() === '')) {
        setUserData(userDataFromApi);
      } else if (selectedCustomer === 'All' && searchedText && searchedText.trim() !== '') {
        const filteredRows = userDataFromApi.data.filter((row: any) => {
          return (
            row.name.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.email.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.role.toLowerCase().includes(searchedText.trim().toLowerCase()) ||
            row.status.toLowerCase().includes(searchedText.trim().toLowerCase())
          );
        });
        let data: any = [];
        let length: any;
        let payLoad = {
          data,
          length,
        };
        payLoad.data = filteredRows;
        payLoad.length = filteredRows.length;
        setUserData(payLoad);
      }
    }
  };

  const getTabsBasedOnRoles = () => {
    if (role) {
      if (Object.keys(role).length) {
        if (role.userRole.toLowerCase() === 'admin') {
          return JSON.stringify([
            { label: 'User Management', icon: 'account_circle', key: 'user' },
            { label: 'Asset Management', icon: 'account_circle', key: 'asset' },
            { label: 'Change requests', icon: 'settings_input_composite', key: 'change-request' },
          ]);
        } else {
          return JSON.stringify([{ label: 'Change requests', icon: 'settings_input_composite', key: 'change-request' }]);
        }
      }
    }
  };

  const selectedButtonTabClick = (event: CustomEvent) => {
    event.detail.fullText === 'Expiring soon' ? setSelectedTab('ExpiringSoon') : setSelectedTab(event.detail.fullText);
    return <RequestDetails status={selectedTab} />
  }

  const getTabContentForAdmin = () => {
    if (isUserTableOpen === 'user') {
      return (
        <div>
          <Card id="user-card">
            <CardHeader
              action={
                <div className="user-action-container">
                  <div className="user-action-wrapper">
                    <BhDropdown
                      label="Customer"
                      menuItems={JSON.stringify(customers)}
                      value={selectedCustomer}
                      id="customer"
                      class="filter-drd"
                      onBhEventChange={(event: any) => handleCustomerFilterChange(event)}
                    ></BhDropdown>
                  </div>
                  <div className="user-action-wrapper">
                    <BhDropdown
                      label="Refinery"
                      menuItems={JSON.stringify(refineries)}
                      value={selectedRefinery}
                      id="refinery"
                      class="filter-drd"
                      onBhEventChange={(event: any) => handleRefineryFilterChange(event)}
                    ></BhDropdown>
                  </div>
                  <div className="user-action-wrapper search-wrapper tp-22">
                    <TextField
                      id="txt-admin-search"
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => requestSearch(event)}
                    />
                  </div>
                  <div className="user-action-wrapper tp-22">
                    <BhButton
                      id="btnExportToExcel"
                      key="btnExportToExcel"
                      onClick={handleUserExportClick}
                      leftIcon="file_download"
                      type="secondary"
                      label="Export to Excel"
                    ></BhButton>
                  </div>
                  <div className="user-action-wrapper tp-22">
                    <BhButton
                      id="btnAddUser"
                      key="btnAddUser"
                      type="primary"
                      label="Add new user"
                      leftIcon="add"
                      isSmall
                      onClick={handleAddUserClick}
                    ></BhButton>
                  </div>
                </div>
              }
              title="Users"
            />
            <CardContent>
              {Object.keys(userData).length > 0 && userData.data && userData.data.length ? (
                <div className="user-table" style={{ height: 500, width: '100%' }}>
                  <DataGrid
                    pagination
                    paginationModel={paginationModelUser}
                    slots={{
                      pagination: CustomPaginationForAdmin,
                    }}
                    rows={userData.data}
                    columns={userColumns}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'name', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    onPaginationModelChange={setPaginationModelUser}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                </div>
              ) : (
                <div style={{ height: 150, width: '100%' }} className="user-table">
                  <DataGrid
                    pagination
                    paginationModel={paginationModelUser}
                    slots={{
                      pagination: CustomPaginationForAdmin,
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={[]}
                    columns={userColumns}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    onPaginationModelChange={setPaginationModelUser}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      );
    }

    if (isUserTableOpen === 'asset') {
      return (
        <div>
          <Card id="user-card">
            <CardHeader
              action={
                <div className="user-action-container">
                  <div className="user-action-wrapper search-wrapper tp-22">
                    <TextField
                      id="txt-asset-search"
                      variant="outlined"
                      size="small"
                      placeholder="Search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(event) => requestAssetSearch(event)}
                    />
                  </div>
                  <div className="user-action-wrapper tp-22">
                    <BhButton
                      id="btnAddAsset"
                      key="btnAddAsset"
                      type="primary"
                      label="Onboard new asset"
                      leftIcon="add"
                      isSmall
                      onClick={handleOnBoardAssetClick}
                    ></BhButton>
                  </div>
                </div>
              }
              title="Assets"
            />
            <CardContent>
              {Object.keys(assetData).length > 0 && assetData.data && assetData.data.length ? (
                <div className="asset-table" style={{ height: 500, width: '100%' }}>
                  <DataGrid
                    pagination
                    slots={{
                      pagination: CustomPaginationForAdmin,
                    }}
                    rows={assetData.data}
                    columns={assetColumns}
                    paginationModel={paginationModelAsset}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'customer', sort: 'desc' }],
                      },
                    }}
                    onPaginationModelChange={setPaginationModelAsset}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                </div>
              ) : (
                <div style={{ height: 150, width: '100%' }} className="asset-table">
                  <DataGrid
                    pagination
                    paginationModel={paginationModelAsset}
                    slots={{
                      pagination: CustomPaginationForAdmin,
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={[]}
                    columns={assetColumns}
                    initialState={{
                      sorting: {
                        sortModel: [{ field: 'customer', sort: 'desc' }],
                      },
                    }}
                    onPaginationModelChange={setPaginationModelAsset}
                    pageSizeOptions={[5, 10, 15, 20, 25]}
                    columnVisibilityModel={{ id: false }}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder
                    rowHeight={44}
                    columnHeaderHeight={42}
                  />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      );
    }

    if (isUserTableOpen === 'change-request') {
      return <RequestDetails status={selectedTab}/>;
    }
  };

  return role &&
    Object.keys(role).length &&
    (role.userRole.toLowerCase() === 'admin' || role.userRole.toLowerCase() === 'sme' || role.userRole.toLowerCase() === 'account manager') &&
    role.userActiveStatus ? (
    <React.Fragment>
      <div className='admin-sub-head'>
        <Breadcrumbs
          className='admin-breadCrumb'
          aria-label="breadcrumb"
        >
          <Link color="inherit" href="/">
            <PublicIcon />
          </Link>
        </Breadcrumbs>
      </div>
      <div className='admin-divider'>
        <BhDivider></BhDivider>
      </div>

      <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
        <div style={{ height: '50px' }}>
          <BhTitleWrapper data-testid="new-sim-title" type="subtitle" header={pageHeader}></BhTitleWrapper>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px', marginRight: '30px' }}>
        <div className="simTabs">
          <BhTabs
            items={getTabsBasedOnRoles()}
            isBorder={true}
            menuWidth="fluid"
            activeKey={isUserTableOpen}
            onBhEventSelected={(event: any) => selectedTabClick(event)}
          ></BhTabs>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isUserTableOpen === 'change-request' ? (
            <div style={{ marginTop: '30px', width: '40%' }}>
              <BhButtonTabs
                items={[
                  { label: 'Pending', key: 'pending' },
                  { label: 'Expiring soon', key: 'expiring' },
                  { label: 'Completed', key: 'completed' }
                ]}
                menuWidth="fluid"
                activeKey={selectedTab === 'ExpiringSoon' ? 'expiring' : (selectedTab === 'Pending' ? 'pending':'completed')}
                onBhEventSelected={(event: any) => selectedButtonTabClick(event)}
              ></BhButtonTabs>
            </div>
          ) : (
            <div style={{ marginTop: '30px' }}></div>
          )}
          </div>
        {role.userRole.toLowerCase() === 'admin' ? getTabContentForAdmin() : <RequestDetails status={selectedTab}/>}
        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
            open={isLoading}
            onClick={handleBackDropClose}
          >
            {isLoading ? <BhSpinner size="large"></BhSpinner> : null}
          </Backdrop>
        </div>
      </div>
      <ManageUserModal
        isOpen={isOpen}
        isAddUser={isAddUser}
        getUserData={getUserData}
        closeModal={closeModal}
        roles={roles}
        allCustomers={allCustomers}
        userInfo={userDetails}
      ></ManageUserModal>
      <ImportAssetsModal getConfigData={props.getConfigData} isOpen={isAssetOpen} getAssetsData={getAssetsData} closeModal={closeAssetModal}></ImportAssetsModal>
      {isUserTableOpen === 'user' ? (
        <Menu open={open} anchorEl={anchorEl} onClose={(event: any) => handleMenuClose(true)} id="newsim-menu">
          <MenuItem id="btnManageUser" onClick={handleManageUserClick}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Manage user</ListItemText>
          </MenuItem>
        </Menu>
      ) : (
        <Menu open={openAssetMenu} anchorEl={anchorElAsset} onClose={(event: any) => handleMenuClose(false)} id="newsim-menu">
          <MenuItem id="btnSyncData" onClick={(event: any) => handleSyncDataClick(event)}>
            <ListItemIcon>
              <RefreshIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sync data</ListItemText>
          </MenuItem>
          <MenuItem id="btnEditData" onClick={(event: any) => handleEditDataClick(event)}>
            <ListItemIcon>
              <ModeEditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        </Menu>
      )}
      {isSuccess ? (
        <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success asset-error" id="alert-message">
          {message}
        </Alert>
      ) : isError ? (
        <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error asset-error" id="alert-message">
          {message}
        </Alert>
      ) : (
        <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
      )}
      <BootstrapDialog onClose={closeSyncDataModal} aria-labelledby="upload-excel-title" open={showSyncData} className="upload-modal">
        <BootstrapDialogTitle id="upload-excel-title" onClose={closeSyncDataModal}>
          <div className="upload-header">Sync data</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <div className="errorinfo-padding">
            {syncDataChangeObj &&
              syncDataChangeObj.map(function (changeSet: any, index: number) {
                return (
                  <>
                  {(Object.keys(changeSet)[0] === 'header') &&
                    <label>{changeSet[Object.keys(changeSet)[0]]}</label>}
                  {Object.keys(changeSet)[0] !== 'NoChange' && Object.keys(changeSet)[0] !== 'header' &&
                    <ul>
                      <li className='changeset-li' key={index}>{changeSet.toString()}</li>
                    </ul>}
                    {Object.keys(changeSet)[0] === 'NoChange' && 
                    <span>{changeSet[Object.keys(changeSet)[0]]}</span>}
                  </>
                );
              })}
          </div>
        </DialogContent>
        <DialogActions>
        </DialogActions>
        </BootstrapDialog>
        <BhModal
            isOpen={showEditData}
            header="Edit asset"
            ctas={ctaData}
            onBhEventCtaClick={(event: any) => {
              handleCtaClick(event);
            }}
            onBhEventClose={closeEditDataModal}
            //isDismissible={false}
            width="small"
            id="edit-asset-modal"
          >
            <div className='edit-data'>
              <div>
            <BhTextInput
                            id="txt-customer"
                            type="text"
                            label="Customer"
                            value={customer}
                            isRequired
                            isError={isCustomerError}
                            onBhEventChange={(event) => {
                              handleInputChange(event);
                            }}
                          ></BhTextInput>
                            <BhFormMessage isError message={isCustomerError ? 'This field is mandatory': ''} id="customer-error"></BhFormMessage>
                            </div>
            </div>
            {isEditSuccess ? (
        <Alert icon={<CheckCircleOutlineIcon />} severity="success" className="alert-box-success asset-edit-error" id="alert-message">
          {editMessage}
        </Alert>
      ) : isEditError ? (
        <Alert icon={<SecurityIcon />} severity="error" className="alert-box-error asset-edit-error" id="alert-message">
          {editMessage}
        </Alert>
      ) : (
        <Alert hidden id="nodisplay-alert" className="config-error"></Alert>
      )}
          </BhModal>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <center>
        <BhIllustration
          id="permission-required"
          source={`${process.env.PUBLIC_URL}/assets/images//PermissionRequired.svg`}
          title="Insufficient rights"
          description="403: You don't have the rights to access this page. "
        ></BhIllustration>
      </center>
    </React.Fragment>
  )
}
