import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BhButton, BhCard, BhDialog, BhTitleWrapper, BhA, BhSpinner } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import { useDispatch, useSelector } from 'react-redux';
import './Dashboard.scss';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteModal from './FavoriteModal';
import { Backdrop, IconButton } from '@mui/material';
import { createNavigationAction } from '../../store/navigation-slice';
import apiRequest from '../../services/api-helper';
import { APIEnum } from '../../constants/api-enum';
import { createConfigAction } from '../../store/config-slice';
import { createFavoriteResponseAction } from '../../store/favoritelist-slice';

import { format } from 'date-fns-tz';
import { dataSetForSearchAndFavoritePayload, fromFavoriteSetData, getDefaultUom } from './helper';
declare const window: any;

const FAV_ROW_WIDTH = 150;
const FAV_ROW_FLEX = 1;

export interface API_STATUS_CODE {
  status: boolean;
  msg: string;
}

const FavoriteList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [moveFavorite, setMoveFavorite] = useState<boolean>(false);
  const [changeUnitOpen, setChangeUnitOpen] = useState<boolean>(false);
  const [favLoad, setFavLoad] = useState<boolean>(false);
  const [selectedSubUnit, setSelectedSubUnit] = useState<number>(0);
  const [selectedSubUnitName, setSelectedSubUnitName] = useState<string>('');
  const favoriteStoreData = useSelector((state: any) => state.createSummaryResponse);
  const refineryResponseFromStore = useSelector((state: any) => state.createRefineryResponse);
  const [isSandbox, setSandbox] = useState(false);

  useEffect(() => {
    setFavLoad(true);
    getFavoriteListData();
  }, []);

  useEffect(() => {
    localStorage.getItem('isSandbox') && localStorage.getItem('isSandbox') === 'true' ? setSandbox(true) : setSandbox(false);
  }, [localStorage.getItem('isSandbox')]);

  const getFavoriteListData = () => {
    let apiUrl = `${window.extended?.IONIC_APP_API_KEY}/subunit/summary`;
    apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then((result) => {
        if (result) {
          setFavLoad(false);
          if (result.responseStatus) {
            if (result.responseStatus.status) {
              if (result.responseStatus.status === 'SUCCESS') {
                dispatch(createFavoriteResponseAction.createSummaryResponse(result.data));
              } else if (result.responseStatus.status === 'NO_DATA') {
                navigate('/dashboard');
                dispatch(createFavoriteResponseAction.createSummaryResponse([]));
              }
            }
          }
        }
      })
      .catch((error) => {
        setFavLoad(false);
      });
  };

  const moveToFavorite = (subUnitId: number, refineryName: string, subName: string) => {
    setSelectedSubUnit(subUnitId);
    setSelectedSubUnitName("'" + refineryName + ' - ' + subName + "'");
    setMoveFavorite(true);
  };

  const reDirectSimulation = async (itemSelected: any) => {
    let favouriteStatus = await upDateDefaultConfig(itemSelected);
    if (favouriteStatus) {
      let responseData: any = await setStoreDataCommon(itemSelected);
      if (responseData) {
        if (responseData.selectedItemRefinery) {
          dispatch(createConfigAction.createConfig(responseData.selectedItemRefinery));
          localStorage.setItem('defaultConfig', JSON.stringify(responseData.selectedItemRefinery));
          localStorage.setItem('refineryApiResult', JSON.stringify(responseData.selectedItemRefinery));
          let subUnitId;
          let configType;
          if (responseData.selectedItemRefinery?.refinery && responseData.unitDetails && responseData.subUnitDetails) {
            let result = fromFavoriteSetData(responseData);
            subUnitId = result.subUnitId;
            configType = result.configType;
            getDefaultUom(subUnitId, configType);
          }
        }
        dispatch(createNavigationAction.createNavigation('simulations'));
        setTimeout(() => {
          navigate('/simulations');
        }, 200);
      }
    }
  };

  const navigateHome = async (navData: any) => {
    let favouriteStatus = await upDateDefaultConfig(navData);
    if (favouriteStatus) {
      let isNav: any = await setStoreData(navData);
      if (isNav) {
        setTimeout(() => {
          navigate('/home');
        }, 200);
        dispatch(createNavigationAction.createNavigation('home'));
      }
    }
  };

  const mapFavouritePayLoad = (items: any[], data: any): any[] => {
    let operator: any;
    items.forEach((operatorItem: any) => {
      let refinery: any = [];
      if (operatorItem.refinery) {
        let unit: any = [];
        if (operatorItem.refinery.unit && operatorItem.refinery.unit.length > 0) {
          operatorItem.refinery.unit.forEach((unitItem: any) => {
            let subUnit: any = [];
            if (unitItem.subUnit && unitItem.subUnit.length > 0) {
              unitItem.subUnit.forEach((subUnitItem: any) => {
                if (subUnitItem.subUnitId === data.subUnitId) {
                  subUnit.push(subUnitItem);
                  let payLoadItems = dataSetForSearchAndFavoritePayload(unitItem, subUnit, operatorItem);
                  operator = {
                    refinery: payLoadItems.refinery,
                  };
                }
              });
            }
          });
        }
      }
    });
    return operator;
  };
  //to update default config
  const upDateDefaultConfig = async (data: any) => {
    let status: boolean = false;
    if (refineryResponseFromStore && refineryResponseFromStore.data && refineryResponseFromStore.data.length) {
      let selectedOperator = mapFavouritePayLoad(refineryResponseFromStore.data, data);
      //api call to save changed unit details
      let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries/favourite`;
      await apiRequest(encodeURI(apiUrl), selectedOperator, APIEnum.POST)
        .then((result) => {
          if (result && result.responseStaus && result.responseStaus.status && result.responseStaus.status === 'SUCCESS') {
            localStorage.removeItem('simulationData');
            localStorage.removeItem('manualInput');
            localStorage.removeItem('sandboxsimulation');
            status = true;
          } else status = false;
        })
        .catch((error) => {
          console.log(error);
          status = false;
        });
    }
    return status;
  };

  const getAdminConfig = async (userId: number) => {
    let createURL = `user/${userId}/defaultconfig`;
    let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/${createURL}`;
    let response = await apiRequest(encodeURI(apiUrl), {}, APIEnum.GET)
      .then(async (result) => {
        if (result) {
          if (result.responseStatus) {
            if (result.responseStatus.status) {
              if (result.responseStatus.status === 'SUCCESS') {
                return result.defaultConfig;
              } else {
                return null;
              }
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  };

  const getChangedParameter = (param: any) => {
    if (param && param.length > 0) {
      return param;
    } else {
      return [];
    }
  };

  const setStoreDataCommon = async (selectedData: any) => {
    if (localStorage.getItem('userId')) {
      let userId: any = localStorage.getItem('userId');
      let apiResponseData: any = await getAdminConfig(userId);
      let selectedItemRefinery: any = { refinery: {} };
      let unitData: any[] = [{}];
      let subUnitData: any[] = [{}];
      if (Object.keys(apiResponseData).length) {
        if (apiResponseData?.unit?.length && Object.keys(apiResponseData.unit[0]).length) {
          if (apiResponseData.unit[0].subUnit.length && Object.keys(apiResponseData.unit[0].subUnit[0]).length) {
            if (apiResponseData?.unit[0].subUnit[0].subUnitId === selectedData.subUnitId) {
              selectedItemRefinery.refinery.refineryId = apiResponseData.refineryId;
              selectedItemRefinery.refinery.refineryName = apiResponseData.refineryName;
              selectedItemRefinery.refinery.refineryType = apiResponseData.refineryType;
              selectedItemRefinery.refinery.customerId = apiResponseData.customerId;
              selectedItemRefinery.refinery.customerName = apiResponseData.customerName;
              selectedItemRefinery.refinery.customerIDSName = apiResponseData.customerIDSName;
              selectedItemRefinery.refinery.operator = apiResponseData.operator;
              unitData[0].unitId = apiResponseData.unit[0].unitId;
              unitData[0].unitName = apiResponseData.unit[0].unitName;
              unitData[0].unitType = apiResponseData.unit[0].unitType;
              unitData[0].preflashPrefractionator = apiResponseData.unit[0].preflashPrefractionator;
              unitData[0].changedParameters = getChangedParameter(apiResponseData.unit[0].changedParameters);
              unitData[0].smeConfig = apiResponseData.unit[0].smeConfig ? apiResponseData.unit[0].smeConfig : {};
              unitData[0].sprayModelConfig = apiResponseData.unit[0].sprayModelConfig ? apiResponseData.unit[0].sprayModelConfig : {};
              selectedItemRefinery.refinery.unit = unitData;

              subUnitData[0].subUnitId = apiResponseData.unit[0].subUnit[0].subUnitId;
              subUnitData[0].configId = apiResponseData.unit[0].subUnit[0].configId;
              subUnitData[0].configType = apiResponseData.unit[0].subUnit[0].configType;
              subUnitData[0].subUnitName = apiResponseData.unit[0].subUnit[0].subUnitName;
              subUnitData[0].typeSubunit = apiResponseData.unit[0].subUnit[0].typeSubunit;
              subUnitData[0].numOvhdStages = apiResponseData.unit[0].subUnit[0].numOvhdStages;
              subUnitData[0].numDesalterStages = apiResponseData.unit[0].subUnit[0].numDesalterStages;
              subUnitData[0].idOvhdStream = apiResponseData.unit[0].subUnit[0].idOvhdStream;
              subUnitData[0].idOvhdStreamUom = apiResponseData.unit[0].subUnit[0].idOvhdStreamUom;
              subUnitData[0].numBranchesOvhdStream = apiResponseData.unit[0].subUnit[0].numBranchesOvhdStream;
              subUnitData[0].waterWash = apiResponseData.unit[0].subUnit[0].waterWash;
              subUnitData[0].equipmentOvhdWashStream = apiResponseData.unit[0].subUnit[0].equipmentOvhdWashStream;
              subUnitData[0].injectedNeutralizerStream = apiResponseData.unit[0].subUnit[0].injectedNeutralizerStream;
              subUnitData[0].washFrequency = apiResponseData.unit[0].subUnit[0].washFrequency;
              subUnitData[0].stageOvhdNeutralizerStream = apiResponseData.unit[0].subUnit[0].stageOvhdNeutralizerStream;
              subUnitData[0].stageOvhdWashStream = apiResponseData.unit[0].subUnit[0].stageOvhdWashStream;
              subUnitData[0].unitId = apiResponseData.unit[0].unitId;
              subUnitData[0].cokerType = apiResponseData.unit[0]?.subUnit[0]?.cokerType;
              selectedItemRefinery.refinery.unit[0].subUnit = subUnitData;
            }
          }
        }
      }
      return {
        selectedItemRefinery: selectedItemRefinery,
        unitDetails: apiResponseData.unit[0],
        subUnitDetails: apiResponseData.unit[0].subUnit[0],
      };
    }
  };

  const setStoreData = async (data: any) => {
    let response: any = await setStoreDataCommon(data);
    if (response) {
      if (response.selectedItemRefinery) {
        dispatch(createConfigAction.createConfig(response.selectedItemRefinery));
        localStorage.setItem('defaultConfig', JSON.stringify(response.selectedItemRefinery));
        localStorage.setItem('refineryApiResult', JSON.stringify(response.selectedItemRefinery));
        let subUnitId;
        let configType;
        if (response.selectedItemRefinery?.refinery && response.unitDetails && response.subUnitDetails) {
          let result = fromFavoriteSetData(response);
          subUnitId = result.subUnitId;
          configType = result.configType;
          getDefaultUom(subUnitId, configType);
        }
      }
      return true;
    }
  };

  const renderColumnOne = (item: any) => {
    return (
      <div className="firstColumn">
        <div className="labelAndIcon">
          <div className="colorText">{item.refineryName}</div>
          <div className="iconDiv">
            <IconButton data-testid="heart-icon" onClick={() => moveToFavorite(item.subUnitId, item.refineryName, item.subUnitName)}>
              <FavoriteIcon className="favIcon" fontSize="small" />
            </IconButton>
          </div>
        </div>
        <div className="labelHeight">
          <span className="labelTextTable"> {item.customerName}</span>
        </div>
        <div className="navigateTextDiv">
          <span className="normalText">
            {item.unitName} <span className="dividerForSub">|</span>
          </span>
          <span className="navigateTextBha">
            {isSandbox ? (
              <span className="normalText">{item.subUnitName}</span>
            ) : (
              <BhA
                className="underLineText"
                onClick={() => navigateHome(item)}
                type="primary"
                href="javascript:void(0)"
                text={item.subUnitName}
                data-testid="navigate-home-test"
              ></BhA>
            )}
          </span>
        </div>
        <div>
          <span className="labelTextTable">Last date run: </span>
          <span className="normalText">{item.lastRunDate ? format(new Date(item.lastRunDate), 'dd MMM, yyyy') : ''}</span>
        </div>
      </div>
    );
  };

  const isRoundItem = (value: any, isRound: boolean, isZero: boolean) => {
    if (isRound) {
      let itemForSplit = value?.split(' ')[0];
      let unitName = value?.split(' ')[1];
      if (itemForSplit && unitName) {
        let itemForBind = Math.round(itemForSplit);
        return isZero ? itemForBind + ' °' + unitName : itemForBind + ' ' + unitName;
      }
    } else {
      return value;
    }
  };

  const renderColumnTwo = (item: any, keyName: string, isRound = false, isZero = false) => {
    return (
      <>
        {item[keyName] ? (
          keyName === 'washrateSaturation' ? (
            <div className="numberText">{Math.round(item[keyName]?.split(' ')[0] * 100)}%</div>
          ) : (
            <div className="numberText">{isRoundItem(item[keyName], isRound, isZero)}</div>
          )
        ) : (
          <div className="no-data-text">no data</div>
        )}
      </>
    );
  };

  const renderColumnSeven = (item: any, keyName: string) => {
    return isSandbox ? (
      <>{item[keyName] ? <div className="numberText">{item[keyName]}</div> : <div className="no-data-text">no data</div>}</>
    ) : (
      <>
        {item[keyName] ? (
          <div data-testid="redirect-item" onClick={() => reDirectSimulation(item)} className="numberTextWithUnderLine">
            {item[keyName]}
          </div>
        ) : (
          <div className="no-data-text">no data</div>
        )}
      </>
    );
  };

  const createFavorite = () => {
    setChangeUnitOpen(true);
  };

  const closeModal = () => {
    setChangeUnitOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'refineryName',
      headerName: '',
      renderCell: (params) => renderColumnOne(params.row),
      width: 310,
      flex: 0,
      sortable: false,
    },
    {
      field: 'convergenceTemp',
      headerName: 'Converging Temperature',
      headerClassName: 'grid-header-favorite',
      width: FAV_ROW_WIDTH,
      renderCell: (params) => renderColumnTwo(params.row, 'convergenceTemp', true, true),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
    {
      field: 'towerTop',
      headerName: 'Lowest tower top dT',
      headerClassName: 'grid-header-favorite',
      width: FAV_ROW_WIDTH,
      renderCell: (params) => renderColumnTwo(params.row, 'towerTop', true, true),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
    {
      field: 'lastExchangeDt',
      headerName: 'Lowest exchanger dT',
      headerClassName: 'grid-header-favorite',
      width: FAV_ROW_WIDTH,
      renderCell: (params) => renderColumnTwo(params.row, 'lastExchangeDt', true, true),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
    {
      field: 'hotDrum',
      headerName: 'Lowest hot drum dT',
      headerClassName: 'grid-header-favorite',
      width: FAV_ROW_WIDTH,
      renderCell: (params) => renderColumnTwo(params.row, 'hotDrum', true, true),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
    {
      field: 'washrateSaturation',
      headerName: 'Wash rate excess over saturation',
      headerClassName: 'grid-header-favorite',
      width: FAV_ROW_WIDTH,
      renderCell: (params) => renderColumnTwo(params.row, 'washrateSaturation', true, false),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
    {
      field: 'velocity',
      headerName: 'Velocity',
      headerClassName: 'grid-header-favorite',
      width: FAV_ROW_WIDTH,
      renderCell: (params) => renderColumnTwo(params.row, 'velocity', true, false),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
    {
      field: 'previousSimulationCount',
      headerName: 'No.of simulations run in previous 90 days',
      headerClassName: 'grid-header-favorite',
      width: 200,
      renderCell: (params) => renderColumnSeven(params.row, 'previousSimulationCount'),
      align: 'center',
      headerAlign: 'center',
      flex: FAV_ROW_FLEX,
      sortable: false,
    },
  ];

  const closeDeleteDialog = () => {
    setMoveFavorite(false);
    setSelectedSubUnit(0);
    setSelectedSubUnitName('');
  };

  const apiResponseDelete = (status: boolean, msgText: string, callConfig = false) => {
    setMoveFavorite(false);
    setSelectedSubUnit(0);
    setSelectedSubUnitName('');
    getFavoriteListData();
  };

  const handleClose = () => {
    setFavLoad(false);
  };

  const deleteDialogSubmitClick = (event: any) => {
    if (event.detail === 'btnCancel') {
      setMoveFavorite(false);
      setSelectedSubUnit(0);
      setSelectedSubUnitName('');
    } else {
      setFavLoad(true);
      let requestData: any = { removedSubUnits: [] };
      let selectedId = selectedSubUnit;
      if (selectedId) {
        requestData.removedSubUnits.push(selectedId);
        let apiUrl = `${window.extended?.IONIC_APP_ASSET_API_KEY}/refineries/favourite`;
        apiRequest(encodeURI(apiUrl), requestData, APIEnum.POST)
          .then((result) => {
            setFavLoad(false);
            if (result?.responseStaus?.status === 'SUCCESS') {
              apiResponseDelete(true, ' Removed successfully', true);
            } else {
              if (result?.responseStaus?.errorResponse[0]?.message) {
                apiResponseDelete(false, result?.responseStaus?.errorResponse[0]?.message);
              } else {
                apiResponseDelete(false, 'Bad Request');
              }
            }
          })
          .catch((error) => {
            apiResponseDelete(false, error.message);
            setFavLoad(false);
          });
      }
    }
  };

  let textData = 'Are you sure want to remove  ' + selectedSubUnitName + ' from favorites ?';

  return (
    <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
      <div style={{ height: '50px' }}>
        <BhTitleWrapper type="subtitle" header="Dashboard"></BhTitleWrapper>
      </div>
      <div className="addBtn">
        <BhButton onClick={() => createFavorite()} id="addFavoriteBtn" type="secondary" label="Add favorites"></BhButton>
      </div>

      <BhCard class="card-wrapper-favorite">
        <div className="favoriteList">
          <div style={{ height: 610, width: '100%' }}>
            <DataGrid
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: 'normal',
                },
                '& .MuiDataGrid-columnHeader': {
                  height: 'unset !important',
                },
                '& .MuiDataGrid-columnHeaders': {
                  maxHeight: '168px !important',
                  borderStyle: 'hidden !important',
                },
              }}
              hideFooter
              pagination
              rows={favoriteStoreData.summary}
              columns={columns}
              disableColumnMenu
              disableRowSelectionOnClick
              disableColumnSelector
              disableDensitySelector
              hideFooterPagination
              rowHeight={120}
              columnHeaderHeight={60}
              getRowId={(row) => row.subUnitId}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'refineryName', sort: 'asc' }],
                },
              }}
            />
          </div>
        </div>
      </BhCard>
      <Backdrop
        onClick={handleClose}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(18, 18, 18, 0.8);' }}
        open={favLoad}
      >
        {favLoad ? <BhSpinner size="large"></BhSpinner> : null}
      </Backdrop>
      <BhDialog
        header="Are you sure?"
        message={textData}
        className="delete-dialog"
        ctas={[
          {
            type: 'secondary',
            label: 'No',
            key: 'btnCancel',
          },
          {
            type: 'primary',
            label: 'Yes',
            key: 'btnDelete',
          },
        ]}
        isOpen={moveFavorite}
        onBhEventCtaClick={(event: any) => deleteDialogSubmitClick(event)}
        onBhEventClose={() => closeDeleteDialog()}
        data-testid="delete-click"
      ></BhDialog>
      <FavoriteModal
        isEdit={true}
        isOpen={changeUnitOpen}
        header="Units"
        getFavoriteListData={getFavoriteListData}
        closeModal={closeModal}
        checkbox={true}
      ></FavoriteModal>
    </div>
  );
};
export default FavoriteList;
