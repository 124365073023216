import { overHeadConditions, towerData } from "./helper";

export const ionicInputFor1B = (formatedDate: string, elementForOneB: any, isFromReport : boolean) => {
    let tempUnit;
    if(!isFromReport) {
      if (elementForOneB.desalter.desalterTemperature_UOM === '°F' || elementForOneB.desalter.desalterTemperature_UOM === 'F') tempUnit = 'F';
      else if (elementForOneB.desalter.desalterTemperature_UOM === '°C' || elementForOneB.desalter.desalterTemperature_UOM === 'C') tempUnit = 'C';
    }

    return {
      inputDate: formatedDate,
      simulationId : !isFromReport ? null : elementForOneB.simulationId, 
      desalter: {
        crudeCharge: parseFloat(elementForOneB.desalter.crudeCharge),
        crudeCharge_UOM: elementForOneB.desalter.crudeCharge_UOM,
        crudeGravity: parseFloat(elementForOneB.desalter.crudeGravity),
        washPercentageData: {
          washPercentage: parseFloat(elementForOneB.desalter.washPercentageData.washPercentage),
          washPercentage2: parseFloat(elementForOneB.desalter.washPercentageData.washPercentage2),
        },
        brineRecyclePercentage: parseFloat(elementForOneB.desalter.brineRecyclePercentage ? elementForOneB.desalter.brineRecyclePercentage : 0),
        percentageAtmH2OtoDesalter: parseFloat(elementForOneB.desalter.percentageAtmH2OtoDesalter ? elementForOneB.desalter.percentageAtmH2OtoDesalter : 0),
        percentageAtmH2OtoSWS: parseFloat(elementForOneB.desalter.percentageAtmH2OtoSWS ? elementForOneB.desalter.percentageAtmH2OtoSWS : 0),
        percentageSSWtoDesalter: parseFloat(elementForOneB.desalter.percentageSSWtoDesalter ? elementForOneB.desalter.percentageSSWtoDesalter : 0),
        desalterTemperature: parseFloat(elementForOneB.desalter.desalterTemperature),
        desalterTemperature_UOM: !isFromReport ? tempUnit : elementForOneB.desalter.desalterTemperature_UOM,
        brinepHData: {
          brinepH: parseFloat(elementForOneB.desalter.brinepHData.brinepH),
          brinepH2: parseFloat(elementForOneB.desalter.brinepHData.brinepH2),
        },
        brineSampleTemperature: parseFloat(elementForOneB.desalter.brineSampleTemperature),
        desCrudeBSWData: {
          desCrudeBSW: parseFloat(elementForOneB.desalter.desCrudeBSWData.desCrudeBSW ? elementForOneB.desalter.desCrudeBSWData.desCrudeBSW : 0),
          desCrudeBSW2: parseFloat(elementForOneB.desalter.desCrudeBSWData.desCrudeBSW2 ? elementForOneB.desalter.desCrudeBSWData.desCrudeBSW2 : 0),
        },
      },
      towerData: towerData(elementForOneB,'2A',false),
      overheadConditions : overHeadConditions(elementForOneB,isFromReport, tempUnit),
      liquidAssayStreams: {
        isWetReflux: !isFromReport ? (elementForOneB.liquidAssayStreams.isWetReflux === true || elementForOneB.liquidAssayStreams.isWetReflux === 'TRUE' ? true : false) : elementForOneB.liquidAssayStreams.isWetReflux ? true : false,
        hydrocarbonLiquid: {
          reflux: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.reflux ? elementForOneB.liquidAssayStreams.hydrocarbonLiquid.reflux : 0),
          reflux_UOM: elementForOneB.liquidAssayStreams.hydrocarbonLiquid.reflux_UOM,
          product: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.product),
          product_UOM: elementForOneB.liquidAssayStreams.hydrocarbonLiquid.product_UOM,
          gravity: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.gravity),
          distillationType: elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillationType,
          distillation: {
            0: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[0]),
            5: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[5]),
            10: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[10]),
            20: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[20]),
            30: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[30]),
            50: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[50]),
            70: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[70]),
            80: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[80]),
            90: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[90]),
            95: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[95]),
            100: parseFloat(elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation[100]),
            distillation_UOM: !isFromReport ? tempUnit : elementForOneB.liquidAssayStreams.hydrocarbonLiquid.distillation.distillation_UOM,
          },
        },
      },
      offGasData: {
        offGas: parseFloat(elementForOneB.offGasData.offGas ? elementForOneB.offGasData.offGas : 0),
        offGas_UOM: elementForOneB.offGasData.offGas_UOM,
        H2: parseFloat(elementForOneB.offGasData.H2 ? elementForOneB.offGasData.H2 : 0),
        H2S: parseFloat(elementForOneB.offGasData.H2S ? elementForOneB.offGasData.H2S : 0),
        CO2: parseFloat(elementForOneB.offGasData.CO2 ? elementForOneB.offGasData.CO2 : 0),
        C1: parseFloat(elementForOneB.offGasData.C1 ? elementForOneB.offGasData.C1 : 0),
        C2: parseFloat(elementForOneB.offGasData.C2 ? elementForOneB.offGasData.C2 : 0),
        C3: parseFloat(elementForOneB.offGasData.C3 ? elementForOneB.offGasData.C3 : 0),
        iC4: parseFloat(elementForOneB.offGasData.iC4 ? elementForOneB.offGasData.iC4 : 0),
        nC4: parseFloat(elementForOneB.offGasData.nC4 ? elementForOneB.offGasData.nC4 : 0),
        iC5: parseFloat(elementForOneB.offGasData.iC5 ? elementForOneB.offGasData.iC5 : 0),
        nC5: parseFloat(elementForOneB.offGasData.nC5 ? elementForOneB.offGasData.nC5 : 0),
        nC6: parseFloat(elementForOneB.offGasData.nC6 ? elementForOneB.offGasData.nC6 : 0),
      },
      overheadWater: {
        combinedRefluxData :{
          combinedRefluxFlow: parseFloat(elementForOneB.overheadWater.combinedRefluxData.combinedRefluxFlow ? elementForOneB.overheadWater.combinedRefluxData.combinedRefluxFlow  : 0),
          combinedRefluxTemp : parseFloat(elementForOneB.overheadWater.combinedRefluxData.combinedRefluxTemp ? elementForOneB.overheadWater.combinedRefluxData.combinedRefluxTemp  : 0),
          combinedRefluxFlow_UOM: elementForOneB.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM,
          combinedRefluxTemp_UOM : !isFromReport ? tempUnit : elementForOneB.overheadWater.combinedRefluxData.combinedRefluxTemp_UOM,
        },
        pHData: {
          pH: parseFloat(elementForOneB.overheadWater.pHData.pH),
        },
        clData: {
          cl: parseFloat(elementForOneB.overheadWater.clData.cl ? elementForOneB.overheadWater.clData.cl : 0),
        },
        bromideData: {
          bromide: parseFloat(elementForOneB.overheadWater.bromideData.bromide ? elementForOneB.overheadWater.bromideData.bromide : 0),
        },
        NH3Data: {
          NH3: parseFloat(elementForOneB.overheadWater.NH3Data.NH3 ? elementForOneB.overheadWater.NH3Data.NH3 : 0),
        },
        neutralizerName: elementForOneB.overheadWater.neutralizerName,
        neutralizerRate: parseFloat(elementForOneB.overheadWater.neutralizerRate ? elementForOneB.overheadWater.neutralizerRate : 0),
        neutralizerRate_UOM: elementForOneB.overheadWater.neutralizerRate_UOM,
        outsideNeutralizer: parseFloat(elementForOneB.overheadWater.outsideNeutralizer ? elementForOneB.overheadWater.outsideNeutralizer : 0),
        outsideNeutralizer_UOM: elementForOneB.overheadWater.outsideNeutralizer_UOM,
        trampAmineData: {
          trampAmine: elementForOneB.overheadWater.trampAmineData.trampAmine,
          trampAmineppm: parseFloat(
            elementForOneB.overheadWater.trampAmineData.trampAmineppm ? elementForOneB.overheadWater.trampAmineData.trampAmineppm : 0
          ),
        },
        steamAmineData: {
          steamAmine: elementForOneB.overheadWater.steamAmineData.steamAmine,
          steamAmineppm: parseFloat(
            elementForOneB.overheadWater.steamAmineData.steamAmineppm ? elementForOneB.overheadWater.steamAmineData.steamAmineppm : 0
          ),
        },
      },
    };
  };
  