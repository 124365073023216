import { distillationFor_2A_2B, distillationFor_HOT_2A_2B, overHeadConditions, towerData } from "./helper";

export const ionicInputFor2A = (formatedDate: string, element: any, isFromReport : boolean, is1C2C: boolean) => {
  let tempUnit;
  if(!isFromReport) {
    if(is1C2C){
      if (element.overheadConditions.OHTemp_UOM === '°F' || element.overheadConditions.OHTemp_UOM === 'F') tempUnit = 'F';
      else if (element.overheadConditions.OHTemp_UOM === '°C' || element.overheadConditions.OHTemp_UOM === 'C') tempUnit = 'C';
    }
    else{
    if (element.desalter.desalterTemperature_UOM === '°F' || element.desalter.desalterTemperature_UOM === 'F') tempUnit = 'F';
    else if (element.desalter.desalterTemperature_UOM === '°C' || element.desalter.desalterTemperature_UOM === 'C') tempUnit = 'C';
    }
  }
    return {
      inputDate: formatedDate,
      simulationId : !isFromReport ? null : element.simulationId, 
      desalter: {
        crudeCharge: parseFloat(element.desalter.crudeCharge),
        crudeCharge_UOM: element.desalter.crudeCharge_UOM,
        crudeGravity: parseFloat(element.desalter.crudeGravity),
        washPercentageData: {
          washPercentage: !is1C2C ? parseFloat(element.desalter.washPercentageData.washPercentage) : null,
        },
        brineRecyclePercentage: !is1C2C ? parseFloat(element.desalter.brineRecyclePercentage ? element.desalter.brineRecyclePercentage : 0) : null,
        percentageAtmH2OtoDesalter: !is1C2C ? parseFloat(element.desalter.percentageAtmH2OtoDesalter ? element.desalter.percentageAtmH2OtoDesalter : 0) : null,
        percentageAtmH2OtoSWS: !is1C2C ? parseFloat(element.desalter.percentageAtmH2OtoSWS ? element.desalter.percentageAtmH2OtoSWS : 0) : null,
        percentageSSWtoDesalter: !is1C2C ? parseFloat(element.desalter.percentageSSWtoDesalter ? element.desalter.percentageSSWtoDesalter : 0) : null,
        desalterTemperature: !is1C2C ? parseFloat(element.desalter.desalterTemperature) : null,
        desalterTemperature_UOM: !is1C2C ? !isFromReport ? tempUnit : element.desalter.desalterTemperature_UOM: null,
        brinepHData: {
          brinepH: !is1C2C ? parseFloat(element.desalter.brinepHData.brinepH) : null,
        },
        brineSampleTemperature: !is1C2C ? parseFloat(element.desalter.brineSampleTemperature) : null,
        desCrudeBSWData: {
          desCrudeBSW: parseFloat(element.desalter.desCrudeBSWData.desCrudeBSW ? element.desalter.desCrudeBSWData.desCrudeBSW : 0),
        },
      },
      towerData: towerData(element,'2A',false),
      overheadConditions: overHeadConditions(element,isFromReport, tempUnit),
      liquidAssayStreams: {
        isWetReflux: !isFromReport ?  (element.liquidAssayStreams.isWetReflux === true || element.liquidAssayStreams.isWetReflux === 'TRUE' ? true : false) : element.liquidAssayStreams.isWetReflux ? true : false,
        hydrocarbonLiquidHot: {
          reflux: parseFloat(
            element.liquidAssayStreams.hydrocarbonLiquidHot.reflux ? element.liquidAssayStreams.hydrocarbonLiquidHot.reflux : 0
          ),
          reflux_UOM: element.liquidAssayStreams.hydrocarbonLiquidHot.reflux_UOM,
          product: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.product),
          product_UOM: element.liquidAssayStreams.hydrocarbonLiquidHot.product_UOM,
          gravity: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidHot.gravity),
          distillationType: element.liquidAssayStreams.hydrocarbonLiquidHot.distillationType,
          distillation : distillationFor_HOT_2A_2B(element, isFromReport, tempUnit)
        },
        hydrocarbonLiquidCold: {
          reflux: parseFloat(
            element.liquidAssayStreams.hydrocarbonLiquidCold.reflux ? element.liquidAssayStreams.hydrocarbonLiquidCold.reflux : 0
          ),
          reflux_UOM: element.liquidAssayStreams.hydrocarbonLiquidCold.reflux_UOM,
          product: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.product),
          product_UOM: element.liquidAssayStreams.hydrocarbonLiquidCold.product_UOM,
          gravity: parseFloat(element.liquidAssayStreams.hydrocarbonLiquidCold.gravity),
          distillationType: element.liquidAssayStreams.hydrocarbonLiquidCold.distillationType,
          distillation: distillationFor_2A_2B(element, isFromReport, tempUnit),
        },
      },
      offGasData: {
        offGas: parseFloat(element.offGasData.offGas ? element.offGasData.offGas : 0),
        offGas_UOM: element.offGasData.offGas_UOM,
        H2: parseFloat(element.offGasData.H2 ? element.offGasData.H2 : 0),
        H2S: parseFloat(element.offGasData.H2S ? element.offGasData.H2S : 0),
        CO2: parseFloat(element.offGasData.CO2 ? element.offGasData.CO2 : 0),
        C1: parseFloat(element.offGasData.C1 ? element.offGasData.C1 : 0),
        C2: parseFloat(element.offGasData.C2 ? element.offGasData.C2 : 0),
        C3: parseFloat(element.offGasData.C3 ? element.offGasData.C3 : 0),
        iC4: parseFloat(element.offGasData.iC4 ? element.offGasData.iC4 : 0),
        nC4: parseFloat(element.offGasData.nC4 ? element.offGasData.nC4 : 0),
        iC5: parseFloat(element.offGasData.iC5 ? element.offGasData.iC5 : 0),
        nC5: parseFloat(element.offGasData.nC5 ? element.offGasData.nC5 : 0),
        nC6: parseFloat(element.offGasData.nC6 ? element.offGasData.nC6 : 0),
      },
      overheadWater: {
        combinedRefluxData :{
          combinedRefluxFlow: parseFloat(element.overheadWater.combinedRefluxData.combinedRefluxFlow ? element.overheadWater.combinedRefluxData.combinedRefluxFlow  : 0),
          combinedRefluxTemp : parseFloat(element.overheadWater.combinedRefluxData.combinedRefluxTemp ? element.overheadWater.combinedRefluxData.combinedRefluxTemp  : 0),
          combinedRefluxFlow_UOM: element.overheadWater.combinedRefluxData.combinedRefluxFlow_UOM,
          combinedRefluxTemp_UOM : !isFromReport ? tempUnit : element.overheadWater.combinedRefluxData.combinedRefluxTemp_UOM,
        },
        pHData: {
          pH: parseFloat(element.overheadWater.pHData.pH),
          pH_2: parseFloat(element.overheadWater.pHData.pH_2),
        },
        clData: {
          cl: parseFloat(element.overheadWater.clData.cl ? element.overheadWater.clData.cl : 0),
          cl_2: parseFloat(element.overheadWater.clData.cl_2 ? element.overheadWater.clData.cl_2 : 0),
        },
        bromideData: {
          bromide: parseFloat(element.overheadWater.bromideData.bromide ? element.overheadWater.bromideData.bromide : 0),
          bromide_2: parseFloat(element.overheadWater.bromideData.bromide_2 ? element.overheadWater.bromideData.bromide_2 : 0)
        },
        NH3Data: {
          NH3: parseFloat(element.overheadWater.NH3Data.NH3 ? element.overheadWater.NH3Data.NH3 : 0),
          NH3_2: parseFloat(element.overheadWater.NH3Data.NH3_2 ? element.overheadWater.NH3Data.NH3_2 : 0),
        },
        neutralizerName: element.overheadWater.neutralizerName,
        neutralizerRate: parseFloat(element.overheadWater.neutralizerRate ? element.overheadWater.neutralizerRate : 0),
        neutralizerRate_UOM: element.overheadWater.neutralizerRate_UOM,
        outsideNeutralizer: parseFloat(element.overheadWater.outsideNeutralizer ? element.overheadWater.outsideNeutralizer : 0),
        outsideNeutralizer_UOM: element.overheadWater.outsideNeutralizer_UOM,
        trampAmineData: {
          trampAmine: element.overheadWater.trampAmineData.trampAmine,
          trampAmineppm: parseFloat(
            element.overheadWater.trampAmineData.trampAmineppm ? element.overheadWater.trampAmineData.trampAmineppm : 0
          ),
          trampAmineppm2: parseFloat(
            element.overheadWater.trampAmineData.trampAmineppm2 ? element.overheadWater.trampAmineData.trampAmineppm2 : 0
          ),
        },
        steamAmineData: {
          steamAmine: element.overheadWater.steamAmineData.steamAmine,
          steamAmineppm: parseFloat(
            element.overheadWater.steamAmineData.steamAmineppm ? element.overheadWater.steamAmineData.steamAmineppm : 0
          ),
          steamAmineppm2: parseFloat(
            element.overheadWater.steamAmineData.steamAmineppm2 ? element.overheadWater.steamAmineData.steamAmineppm2 : 0
          ),
        },
      },
    };
  };