import React, { useLayoutEffect, useState } from 'react';
import Configuration from '../Configuration/Configuration';
import ChangeUnitModal from '../Shared/Modal/ChangeUnitModal';
import PlantDetails from '../Shared/PlantDetails/PlantDetails';


 export default function LandingPage(props:any) {
  const { getConfigData, getConfigDataForSandBox, getNotification } = props;
  const [isOpen, setOpenFlag] = useState<boolean>(false);

  const closeModal = () => {
    setOpenFlag(false);
  };

  useLayoutEffect(() => {
    if(!localStorage.getItem('found')) {
      return;
    }
    getNotification(false);
  },[]);

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'column', height: '80px', marginLeft: '30px', marginRight: '30px' }}>
        <PlantDetails getConfigData={getConfigData} getConfigDataForSandBox={getConfigDataForSandBox}></PlantDetails>
      </div>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
        <Configuration  getConfigData={getConfigData} getConfigDataForSandBox={getConfigDataForSandBox}></Configuration>
      </div>
      <ChangeUnitModal
        isOpen={isOpen}
        header="Select unit"
        isDismissible={false}
        getConfigData={getConfigData}
        closeModal={closeModal}
      ></ChangeUnitModal>
    </React.Fragment>
  );
}
