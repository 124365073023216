import React, { useEffect, useState } from 'react';
import { BhButton, BhCard, BhIllustration, BhTitleWrapper } from '@bh-digitalsolutions/ui-toolkit-react/dist/components';
import FavoriteModal from './FavoriteModal';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './Dashboard.scss';

const Dashboard = () => {
  const navigate = useNavigate();
  const [changeUnitOpenFromDashboard, setChangeUnitOpenFromDashboard] = useState<boolean>(false);
  const favoriteStoreData = useSelector((state: any) => state.createSummaryResponse);

  const addFavorite = () => {
    setChangeUnitOpenFromDashboard(true);
  };

  useEffect(() => {

    if (Object.keys(favoriteStoreData).length) {
       if(favoriteStoreData.summary && favoriteStoreData.summary.length > 0) {
          navigate('/');
       }
   
    }
  }, [favoriteStoreData]);

  const closeModal = () => {
    setChangeUnitOpenFromDashboard(false);
  };

  return (
    <div style={{ display: 'flex', marginLeft: '30px', marginRight: '30px', flexDirection: 'column' }}>
      <div>
        <BhTitleWrapper type="subtitle" header="Dashboard"></BhTitleWrapper>
      </div>
      <BhCard class="card-wrapper-favorite">
        <div className="dashboardContainer">
          <BhIllustration
            id="permission-required"
            source={`${process.env.PUBLIC_URL}/assets/images//broom.svg`}
            title="Favorites Empty!"
            description="Please add subunits from the list."
            className="forText"
          ></BhIllustration>
          <div className="addFavoriteBtn">
            <BhButton data-testid="add-favorite" onClick={() => addFavorite()} id="addFavorite" type="primary" label="Add favorites"></BhButton>
          </div>
        </div>
      </BhCard>
      <FavoriteModal
        isEdit={false}
        isOpen={changeUnitOpenFromDashboard}
        header="Units"
        getConfigData={false}
        closeModal={closeModal}
        checkbox={true}
      ></FavoriteModal>
    </div>
  );
};
export default Dashboard;
